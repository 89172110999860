@import '../../../../assets/styles/vars';
@import '../../../../assets/styles/mixins';

.dashboard {
  min-height: 100vh;
  width: 100%;
  background: transparent linear-gradient(223deg, $dashboard-background-primary 0%, $dashboard-background-secondary 100%);
  flex-wrap: nowrap !important;

  .dashboard_content{
    flex-direction: column;
    padding: 1.8vw 3.5vw;

    .page_title {
      width: 100%;
      display: flex;
      @include flex-box(flex-start, center, row);
      margin-top: 1.866vw;
    }
    .ws_todo_container{
      width: 100%;
      margin: 1.364vw 0 3.23vw;

      .ws_todo {
        padding: 2vw 2.05vw;
        border-left: 1px solid $primary-border-color;

        .empty_data_warning {
          margin-top: 0;
        }

        .ws_todo_heading {
          @include text-styles(1.05vw, 500, $user-profile-name-color, left, $primary-font-family);
          margin-bottom: 1vw;
        }

        .ws_todo_list {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          .ws_todo_item {
            display: flex;
            @include flex-box(space-between, center, row);
            max-height: 2.2vw;


            &:not(:last-child) {
              margin-bottom: 2.2vw;
            }

            .ws_todo_item_company {
              background-color: $company-badge-color;
              border-radius: 4.4px;
              padding: 0.25vw 0.35vw;
              @include text-styles(0.6vw, 600, white, center, $primary-font-family);
            }

            .ws_todo_icon {
              min-width: 1.3vw;
              min-height: 1.3vw;
              width: 1.3vw !important;
              height: 1.3vw !important;
              margin-right: 0.8vw;

              &--weekly_status {
                @include icon-styles(inherit, inherit, "weekly-status-notifcation.svg", contain);
              }
              &--goals {
                @include icon-styles(inherit, inherit, "goals-notification.svg", contain);
              }
              &--reviews {
                @include icon-styles(inherit, inherit, "review-notification.svg", contain);
              }
              &--check-ins {
                @include icon-styles(inherit, inherit, "check-ins-notification.svg", contain);
              }
              &--surveys {
                @include icon-styles(inherit, inherit, "surveys-notification.svg", contain);
              }
            }

            .ws_todo_info {
              flex: 1;
              min-width: 1%;
              margin-right: 1vw;

              .ws_todo_name {
                @include text-styles(0.82vw, 400, $check-in-creator-color, left, $primary-font-family);
                @include text-elipses();
                max-width: 100%;
                display: inline-block;
                text-decoration: underline;
                margin-bottom: 0;
                line-height: initial;
                &:hover {
                  color: $check-in-creator-color-hover;
                }
              }
              .ws_todo_remaining {
                @include text-styles(0.728vw, 400, $hierarchy-btn-color, left, $primary-font-family);
                margin-bottom: 0;
                line-height: initial;
              }
            }

            .ws_todo_assignees {
              display: flex;
              @include flex-box(flex-end, center, row);
              margin-right: 0.5vw;

              &:last-child {
                margin-right: 0;
              }

              .ws_todo_assignee {
                display: flex;
                @include flex-box(center, center, row);
                width: 1.364vw;
                height: 1.364vw;
                margin-right: 0.2vw;

                div.avatar_skeleton, div.avatar_skeleton span {
                  width: 1.364vw;
                  height: 1.364vw;
                }
              }
              .ws_todo_assignee_img {
                @include img-styles(100%, 100%);
              }
              .ws_todo_assignee_team {
                max-width: 4vw;
                min-width: 2vw;
                line-height: 1;
                margin-right: 0.45vw;
                padding: 0.25vw 0.35vw;
                @include text-elipses();

                &:last-child {
                  margin-right: 0;
                }
              }
              .ws_todo_assignees_counter {
                @include text-styles(0.82vw, 500, $role-color, left, $primary-font-family);
              }
            }
          }
        }

        .ws_todo_list_full_list {
          margin-top: 2.4vw;
        }
      }
    }
  }

  .pulse_point_section {
    position: fixed;
    bottom: 15px;
    z-index: 11;  // this because of the "intercom-frame" had the biggest z-index, so we had to exceed it
    right: 14px;
  }
}

// Modal
.ws_modal {
  width: $modal-desktop-size;

  .modal_content {
    width: 100%;
    padding-bottom: 0 !important;
    margin-bottom: 1.35vw;

    .todo-list-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;

        &::before {
          border: none;
        }

        .ant-tabs-nav-operations {
          display: none;
        }

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding-top: 0;
              margin-right: 0;

              &:nth-child(2n) {
                margin: 0 1vw;
              }

              .ant-tabs-tab-btn {
                .todo_list_tab {
                  min-width: 8.182vw;
                  background-color: $tab-primary-color;
                  padding: 0.8vw;
                  border-radius: 9px;
                  color: #ffffff;
                  display: flex;
                  @include flex-box(center, center, column);
                }
                .todo_list_tab_days {
                  @include text-styles(1vw, 400, #ffffff, left, $primary-font-family);
                  margin-bottom: 0;
                }
                .todo_list_tab_count {
                  display: flex;
                  @include flex-box(center, center, column);
                  @include text-styles(3vw, 400, #ffffff, left, $primary-font-family);
                  margin: 0;
                  line-height: 3.5vw;

                  > div {
                    display: flex;
                  }

                  span {
                    line-height: initial;
                    font-size: 1vw;
                  }

                  .todo_list_tab_days_count {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ws_todo_list_wrapper {
    padding-bottom: 2vw;
    width: 100%;
  }
  .ws_todo_list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    max-height: 16.545vw;
    overflow-y: auto;
    width: 100%;

    .ws_todo_item {
      display: flex;
      @include flex-box(space-between, center, row);
      padding: 1.1vw 2vw 1.1vw;
      border-bottom: solid 1px #EAE4F4;

      &:first-child {
        border-top: solid 1px #EAE4F4;
      }

      .ws_todo_item_company {
        background-color: $company-badge-color;
        border-radius: 4.4px;
        padding: 0.25vw 0.35vw;
        @include text-styles(0.6vw, 600, white, center, $primary-font-family);
      }

      .ws_todo_icon {
        min-width: 1.5vw;
        min-height: 1.5vw;
        width: 1.5vw !important;
        height: 1.5vw !important;
        margin-right: 1vw;

        &--weekly_status {
          @include icon-styles(inherit, inherit, "weekly-status-notifcation.svg", contain);
        }
        &--goals {
          @include icon-styles(inherit, inherit, "goals-notification.svg", contain);
        }
        &--reviews {
          @include icon-styles(inherit, inherit, "review-notification.svg", contain);
        }
        &--check-ins {
          @include icon-styles(inherit, inherit, "check-ins-notification.svg", contain);
        }
        &--surveys {
          @include icon-styles(inherit, inherit, "surveys-notification.svg", contain);
        }
      }

      .ws_todo_info {
        flex: 1;
        min-width: 1%;
        margin-right: 1vw;

        .ws_todo_name {
          @include text-styles(0.82vw, 400, $user-profile-name-color, left, $primary-font-family);
          @include text-elipses();
          max-width: 100%;
          display: inline-block;
          border-bottom: 1px solid $user-profile-name-color;
          margin-bottom: 0;
        }
        .ws_todo_remaining {
          @include text-styles(0.728vw, 400, $check-in-creator-color, left, $primary-font-family);
          margin-bottom: 0;
        }
      }

      .ws_todo_assignees {
        display: flex;
        @include flex-box(flex-end, center, row);
        margin-right: 0.5vw;

        &:last-child {
          margin-right: 0;
        }

        .ws_todo_assignee {
          display: flex;
          @include flex-box(center, center, row);
          width: 1.591vw;
          height: 1.591vw;
          margin-right: 0.3vw;

          div.avatar_skeleton, div.avatar_skeleton span {
            width: 1.364vw;
            height: 1.364vw;
          }
        }
        .ws_todo_assignee_img {
          @include img-styles(100%, 100%);
        }
        .ws_todo_assignee_team {
          max-width: 4vw;
          min-width: 2vw;
          margin-right: 0.45vw;
          @include text-elipses();

          &:last-child {
            margin-right: 0;
          }
        }
        .ws_todo_assignees_counter {
          @include text-styles(0.82vw, 500, $role-color, left, $primary-font-family);
        }
      }
    }
  }
}


@include small-desktop-media {
  .ws_modal {
    .ws_todo_list .ws_todo_item .ws_todo_info {
      .ws_todo_name {
        font-size: 1vw;
      }
      .ws_todo_remaining {
        font-size: 0.84vw;
      }
    }
    .modal_content {
      .ws_modal_title {
        font-size: 1.745vw;
      }
    }
  }
}

@include tablet-media {
  .ws_modal {
    width: 75vw;

    .ws_todo_list {
      max-height: 28.5vw;
    }
    .ws_todo_list .ws_todo_item {
      padding: 3.094vw 5.05vw;

      .ws_todo_item_company {
        font-size: 1.719vw;
        padding: 0.5vw 0.6vw;
      }
      .ws_todo_icon {
        min-width: 4.297vw;
        min-height: 4.297vw;
        width: 4.297vw !important;
        height: 4.297vw !important;
        margin-right: 2.604vw;
      }
      .ws_todo_info {
        margin-right: 2.865vw;
        .ws_todo_name {
          font-size: 2.349vw;
        }
        .ws_todo_remaining {
          font-size: 2.085vw;
        }
      }
      .ws_todo_assignees {
        margin-right: 1vw;

        .ws_todo_assignee {
          width: 3.906vw;
          height: 3.906vw;
          margin-right: 0.5vw;

          div.avatar_skeleton, div.avatar_skeleton span {
            width: 3.906vw;
            height: 3.906vw;
          }
        }
        .ws_todo_assignee_team {
          max-width: 18vw;
          margin-right: 0.7vw;
        }
        .ws_todo_assignees_counter {
          font-size: 2.349vw;
        }
      }
    }
    .modal_content {
      margin-bottom: 0;
      .ws_modal_title {
        font-size: 4.426vw;
      }
      .todo-list-tabs {
        .ant-tabs-nav {
          width: 100%;
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                width: 100%;
                margin: 0;
                padding-left: 0;
                .ant-tabs-tab-btn {
                  width: 100%;
                  .todo_list_tab {
                    min-width: 19.531vw;
                    padding: 1.953vw;
                  }
                  .todo_list_tab_days {
                    font-size: 2.865vw;
                  }
                  .todo_list_tab_count {
                    font-size: 7.813vw;
                    line-height: 8vw;

                    span {
                      font-size: 2.865vw;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dashboard {
    .dashboard_content {
      padding: 2vw 5.277vw;

      .page_title {
        margin-top: 0;
      }
      .ws_todo_container {
        justify-content: flex-start;
        margin: 2.604vw 0 3.23vw 0;

        .ws_todo {
          padding: 2.5vw 2.28vw;
          border-left: none;
          border-top: 1px solid $primary-border-color;

          .ws_todo_heading {
            font-size: 2.5vw;
            margin-bottom: 2.6vw;
          }
          .ws_todo_list {
            max-width: 82.85vw;
            .ws_todo_item {
              max-height: unset;
              &:not(:last-child) {
                margin-bottom: 2.6vw;
              }
              .ws_todo_item_company {
                font-size: 1.719vw;
                padding: 0.5vw 0.6vw;
              }
              .ws_todo_icon {
                min-width: 3.776vw;
                min-height: 3.776vw;
                width: 3.776vw !important;
                height: 3.776vw !important;
                margin-right: 2.6vw;
              }
              .ws_todo_info {
                max-width: unset;
                margin-right: 2vw;

                .ws_todo_name {
                  font-size: 2.344vw;
                }
                .ws_todo_remaining {
                  font-size: 2.083vw;
                  opacity: 60%;
                }
              }
              .ws_todo_assignees {
                margin-right: 1vw;

                .ws_todo_assignee {
                  width: 3.255vw;
                  height: 3.255vw;
                  margin-right: 0.7vw;

                  div.avatar_skeleton, div.avatar_skeleton span {
                    width: 3.255vw;
                    height: 3.255vw;
                  }
                }
                .ws_todo_assignee_team {
                  max-width: 18vw;
                  margin-right: 0.911vw;
                  padding: 0.716vw 1.003vw;
                }
                .ws_todo_assignees_counter {
                  font-size: 2.349vw;
                }
              }
            }
          }
          .ws_todo_list_full_list {
            font-size: 1.693vw;
            padding: 0.8vw 1.888vw;
            margin-top: 2.6vw;
            border-bottom: 2px solid $tab-primary-color;
          }
        }
      }
    }
  }
}



@include mobile-media {
  .ws_modal {
    width: $modal-mobile-size;
    .ws_todo_list {
      max-height: 88.016vw;
    }
    .ws_todo_list .ws_todo_item {
      flex-wrap: wrap;
      padding: 6.336vw 5.05vw;
      .ws_todo_item_company {
        font-size: 3.3vw;
        padding: 1vw 1.5vw;
      }
      .ws_todo_icon {
        min-width: 8vw;
        min-height: 8vw;
        width: 8vw !important;
        height: 8vw !important;
        margin-right: 0;
      }
      .ws_todo_info {
        max-width: unset;
        width: 85%;
        margin-right: 0;
        flex: unset;
        .ws_todo_name {
          font-size: 3.733vw;
        }
        .ws_todo_remaining {
          font-size: 3.2vw;
          opacity: 60%;
        }
      }
      .ws_todo_assignees {
        width: 45%;
        justify-content: flex-start;
        margin-top: 2.667vw;

        .ws_todo_assignee {
          width: 8vw;
          height: 8vw;
          margin-right: 1.333vw;

          div.avatar_skeleton, div.avatar_skeleton span {
            width: 8vw;
            height: 8vw;
          }
        }
        .ws_todo_assignee_team {
          max-width: 30vw;
          margin: 0 2.64vw 0 0;
          font-size: 3.2vw;
        }
        .ws_todo_assignees_counter {
          font-size: 4.3vw;
        }
      }
    }
    .modal_content {
      margin-bottom: 0;
      padding: 0 5.9vw 3vw !important;

      .ws_modal_title {
        font-size: 5.867vw;
      }
      .todo-list-tabs {

        .ant-tabs-nav {
          width: 100%;
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                width: 100%;
                margin: 0;
                padding-left: 0;

                &:nth-child(2) {
                  margin: 0;
                }

                .ant-tabs-tab-btn {
                  width: 100%;

                  .todo_list_tab {
                    position: relative;
                    width: 100%;
                    flex-direction: row-reverse;
                    padding: 4vw 2vw;
                  }
                  .todo_list_tab_days {
                    display: none;
                    font-size: 4vw;
                  }
                  .todo_list_tab_count {
                    flex-direction: row;
                    font-size: 11vw;
                    line-height: 9.6vw;

                    > div {
                      margin-left: 4vw;
                      @include flex-box(center, flex-start, column);

                      span {
                        font-size: 4vw;
                      }
                      .todo_list_tab_days_count {
                        display: flex;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dashboard {
    .dashboard_content {
      padding: 3.4vw 3.5vw;

      .page_title {
        margin-top: 0;
      }
      .ws_todo_container {
        justify-content: flex-start;
        margin-top: 2.667vw;
        margin-bottom: 1vw;

        .ws_todo {
          padding: 3.3vw 2.28vw 3.3vw 4.28vw;
          box-shadow: 0 0 0.7vw rgb(206 185 232 / 16%);
          border: 1px solid #EAE4F4;
          height: 100%;
          background-color: #ffffff;
          margin-top: 2.667vw;

          .ws_todo_heading {
            font-size: 4.3vw;
            margin-bottom: 3vw;
          }
          .ws_todo_list {
            .ws_todo_item {
              max-height: unset;

              &:not(:last-child) {
                margin-bottom: 5vw;
              }

              .ws_todo_item_company {
                font-size: 3.3vw;
                padding: 1vw 1.5vw;
              }

              .ws_todo_icon {
                min-width: 6.667vw;
                min-height: 6.667vw;
                width: 6.667vw !important;
                height: 6.667vw !important;
                margin-right: 3.5vw;
              }

              .ws_todo_info {
                max-width: unset;
                margin-right: 4vw;

                .ws_todo_name {
                  font-size: 3.733vw;
                }
                .ws_todo_remaining {
                  font-size: 3.2vw;
                  opacity: 60%;
                }
              }
              .ws_todo_assignees {
                margin-right: 2.667vw;

                .ws_todo_assignee {
                  width: 6.667vw;
                  height: 6.667vw;
                  margin-right: 1.5vw;

                  div.avatar_skeleton, div.avatar_skeleton span {
                    width: 6.667vw;
                    height: 6.667vw;
                  }
                }
                .ws_todo_assignee_team {
                  max-width: 30vw;
                  padding: 1vw 1.5vw;
                  margin: 0 2.64vw 0 0;
                  font-size: 3.2vw;
                }
                .ws_todo_assignees_counter {
                  font-size: 4.3vw;
                }
              }
            }
          }
          .ws_todo_list_full_list {
            width: 100%;
            text-align: center;
            font-size: 3.467vw;
            font-weight: 600;
            padding: 1.9vw 8vw;
            margin-top: 6vw;
            border-bottom: 2px solid $tab-primary-color;
          }
        }
      }
    }
  }
}
