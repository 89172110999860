@import '../../../assets/styles/vars';
@import '../../../assets/styles/mixins';

.footer_wrapper {
  border-top: 0.05vw solid #eaeaea;
  background-color: $footer-bg-color;
  color: $footer-font-color;
  font-family: $primary-font-family;
  font-size: 0.818vw;
  width: 100%;
  .footer_inner {
    padding: 3.5vw;
    display: flex;
    @include flex-box(flex-start, center, row);
    .footer_left {
      margin-right: 12.591vw;
      display: flex;
      @include flex-box(center, center, row);
      .text {
        margin-right: 0.427vw;
        white-space: nowrap;
      }

      .icon {
        img {
          width: 2.727vw;
        }
      }
    }

    .footer_right {
      .top_info {
        margin-bottom: 0.2vw;
      }
    }
  }
}

@include tablet-media {
  .footer_wrapper {
    border-top: 0.2vw solid #eaeaea;
    font-size: 1.844vw;
    .footer_inner {
      padding: 3.5vw;
      .footer_left {
        display: flex;
        @include flex-box(center, center, row);
        .text {
          margin-right: 1.302vw;
        }

        .icon {
          img {
            width: 7.813vw;
          }
        }
      }

      .footer_right {
        .top_info {
          margin-bottom: 0.8vw;
        }
      }
    }
  }
}

@include mobile-media {
  .footer_wrapper {
    border-top: 0.3vw solid #eaeaea;
    font-size: 2.2vw;
    .footer_inner {
      padding: 3.5vw;
      .footer_left {
        margin-right: 4.6vw;
        @include flex-box(center, center, column);
        .text {
          margin: 0 0 0;
          text-transform: uppercase;
        }

        .icon {
          img {
            width: 10.5vw;
          }
        }
      }

      .footer_right {
        .top_info {
          margin-bottom: 1.4vw;
          font-size: 2.4vw;
        }
        .bottom_info {
          font-size: 2.4vw;
        }
      }
    }
  }
}