@import "../../../assets/styles/mixins";

.loader {
  border: 3px solid #5678FA;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader_large {
  width: 2.545vw;
  height: 2.545vw;
}

.loader_default {
  width: 1.727vw;
  height: 1.727vw;
}

.loader_small {
  width: 0.818vw;
  height: 0.818vw;
}

.loader_container {
  display: flex;
  @include flex-box(center,center,column);
}

@include tablet-media {
  .loader_large {
    width: 6.771vw;
    height: 6.771vw;
  }

  .loader_default {
    width: 4.948vw;
    height: 4.948vw;
  }

  .loader_small {
    width: 2.344vw;
    height: 2.344vw;
  }
}

@include mobile-media {
  .loader_large {
    width: 13.867vw;
    height: 13.867vw;
  }

  .loader_default {
    width: 10.133vw;
    height: 10.133vw;
  }

  .loader_small {
    width: 4.8vw;
    height: 4.8vw;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}