@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

html {
  scroll-behavior: smooth;
  --antd-wave-shadow-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: overlay;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .semi_circle_chart_specific_line {
    height: 100%;
  }

  .enraged_line {
    background-color: #E84362;
  }

  .disappointed_line {
    background-color: #F2802C;
  }

  .neutral_line {
    background-color: #F3A816;
  }

  .content_line {
    background-color: #20ADDA;
  }

  .happy_line {
    background-color: #6DC523;
  }

  .strongly_agree {
    background-color: #15803d;
  }

  .agree {
    background-color: #84cc16;
  }

  .neutral {
    background-color: #F3A816;
  }

  .disagree {
    background-color: #f97316;
  }

  .strongly_disagree {
    background-color: #dc2626;
  }

  .chart_tooltip {
    display: flex;
    @include flex-box(center, center, column);
    background: $primary-border-color;
    border: 1px solid $adding-sect-border-color;
    border-radius: 0.15vw;
    padding: 0.25vw 0.35vw;
    transition: transform .15s ease-in-out;

    span {
      @include text-styles(0.72vw, 600, $user-profile-name-color, left, $primary-font-family);
    }
  }

  .chart_tooltip_first_point {
    transform: translateX(3.25vw);
    -webkit-transform: translateX(3.25vw);
  }

  .chart_skeleton {
    height: 10.75vw;

    span {
      height: 100% !important;
    }
  }

  .goal_card_actions_menu {
    cursor: pointer;
    margin-left: 1.2vw;
    @include icon-styles(1vw, 1vw, "shapes-and-symbols.svg", cover);
  }

  .enps_sect {
    align-items: flex-end!important;

    .enps_likely_explanation {
      margin: 0 1.1vw 0.7vw 0;
    }

    .enps_result {
      margin-bottom: 0.3vw;
      line-height: 1;
    }
  }

  .enps_item {
    align-items: flex-end!important;
  }

  .enps_result {
    margin-bottom: 0.3vw;
    line-height: 1;
  }

  .enps_satisfaction_rate_container {
    width: max-content;
    position: relative;
  }

  .enps_likely_explanation {
    margin: 0.7vw 1.1vw 0.7vw 0;
    display: flex;
    @include flex-box(space-between!important, center, row);

    span {
      @include text-styles(0.75vw, 500, $role-color, left, $primary-font-family);
    }
  }

  .enps_satisfaction_rate_numbers {
    display: flex;
    @include flex-box(center, center, row);
  }

  .enps_rate_numbers {
    position: absolute;
    margin: 0!important;
    color: black !important;
    display: flex;
    @include flex-box(center, center, row);
    @include text-styles(0.75vw!important, 500, black, left, $primary-font-family);
  }

  .satisfaction_rate_sect_ENPS {
    line-height: 1.2vw!important;

    li {
      &.ant-rate-star-full {
        .enps_rate_numbers {
          color: white !important;
        }
      }

      &.ant-rate-star-full:nth-child(1),
      &.ant-rate-star-full:nth-child(2),
      &.ant-rate-star-full:nth-child(3),
      &.ant-rate-star-full:nth-child(4),
      &.ant-rate-star-full:nth-child(5),
      &.ant-rate-star-full:nth-child(6),
      &.ant-rate-star-full:nth-child(7) {
        span{
          color: $detractors-color !important;
        }
      }

      &.ant-rate-star-full:nth-child(8),
      &.ant-rate-star-full:nth-child(9) {
        span{
          color: $passives-color !important;
        }
      }

      &.ant-rate-star-full:nth-child(10),
      &.ant-rate-star-full:nth-child(11) {
        span{
          color: $promoters-color !important;
        }
      }
    }
  }

  .stars_sect {
    position: relative;
    width: 100%;
    min-height: 2.25vw;

    .satisfaction_rate_sect {
      position: absolute;

      li {
        margin-right: 0.2vw !important;
        font-size: 0.35vw !important;
        span{
          color: $primary-border-color !important;
        }
      }

      li.ant-rate-star-full{
        span{
          color: $rate-stars-color !important;
        }
      }
    }
  }

  .custom_intercom {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    z-index: 111;
    width: 100%;
    height: 3.364vw;
    padding: 0 1.1vw;
    background: transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    @include text-styles(0.727vw, 500, white, left, $primary-font-family);
    display: flex;
    @include flex-box(flex-start, center, row);

    svg {
      margin-right: 0.818vw;
    }
  }

  .custom_intercom_collapsed {
    justify-content: center!important;
    svg {
      margin-right: 0!important;
    }
  }

  .intercom-namespace {
    .intercom-vlysn8 {
      transform-origin: left bottom!important;
    }

    .intercom-xp7hdt {
      transform-origin: left bottom!important;
    }
  }

  .intercom-lightweight-app-messenger {
    transform-origin: bottom left!important;
  }

  &::-webkit-scrollbar {
    width: 0.82vw;
    height: 0.82vw;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 0.3vw solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: $btn-secondary-color;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  input[type=number], input[type=password], input[type=text], textarea {
    -webkit-appearance: none;
  }

  [role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  #modal-root {
    .modal_overlay {
      &::-webkit-scrollbar {
        width: 0.82vw;
        height: 0.82vw;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 0.3vw solid transparent;
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: $tab-primary-color;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }

      @keyframes fadeOutModal {
        from {
          background-color: rgba(0, 0, 0, 0);
        }
        to {
          background-color: rgba(26, 17, 83, 0.85);
        }
      }

      position: fixed;
      z-index: 60;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
      background-color: rgba(26, 17, 83, 0.85);
      transition: background-color .4s ease-in-out;
      animation: .4s ease-in-out fadeOutModal;
    }

    .modal_container {
      display: flex;
      @include flex-box(center, center, row);
      width: 100%;
      min-height: 100%;

      .apm_modal {
        @keyframes modalZoom {
          from {
            opacity: 0;
            transform: scale(0);
            box-shadow: 0 0 0 #1C0D1F29;
          }
          to {
            opacity: 1;
            transform: scale(1);
            box-shadow: -10px 0px 20px #1C0D1F29;
          }
        }

        z-index: 60;
        background-color: #ffffff;
        margin: 40px auto;
        display: flex;
        @include flex-box(center, center, column);
        opacity: 1;
        animation: .4s ease-in-out modalZoom;

        .modal_content {
          width: 100%;
          padding: 0 2vw 2vw;
        }
      }

      .remove_animation {
        transition: 0s !important;
        animation: unset !important;
      }

      .search_box {
        display: flex;
        @include flex-box(center, center, row);
        position: relative;
        width: 100%;
        z-index: 2;

        .secondary_search {
          background-color: rgba(235, 231, 245, 0.5) !important;
          border-top: 1px solid #D2CAE0;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #D2CAE0;
          width: inherit;
          outline: none;
          height: 3.5vw;
          padding: 0 2vw 0 4vw;
          @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);
        }

        .search_icon {
          position: absolute;
          left: 2vw;
          color: $user-profile-name-color;
          font-size: 1.24vw;
        }

        .clear_search {
          cursor: pointer;
          position: absolute;
          right: 3.5%;
          @include icon-styles(0.75vw, 0.75vw, "close.svg", contain);
        }
      }
    }
  }

  .pulse_point_pop_up {
    @keyframes openPopUp {
      from {
        opacity: 0;
        transform: translate(50%, 50%) scale(0);
        box-shadow: 0 0 0 #1C0D1F29;
      }
      to {
        opacity: 1;
        transform: translate(0, 0) scale(1);
        box-shadow: -10px 0px 20px #1C0D1F29;
      }
    }

    animation: .3s ease-in-out openPopUp;
  }

  h1.page_title {
    line-height: 1;
    margin-bottom: 0;
    display: flex;
    @include flex-box(center, center, row);
    @include text-styles(1.2vw, 500, $user-profile-name-color, left, $primary-font-family);
  }

  .page_title_info {
    margin: 0;
    word-break: break-word;
  }
  .page_title_info_link {
    text-decoration: underline;
  }

  p.secondary_title {
    letter-spacing: 0;
    margin-bottom: 0;
    line-height: 1;
    @include text-styles(1.05vw, 500, $user-profile-name-color, left, $primary-font-family);
  }

  .multiple_select_checkbox {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 13px;
        height: 13px;
        border-color: #d9d9d9 !important;

        &::after {
          top: 45%;
          width: 4.714286px;
          height: 8.142857px;
        }
      }

      &-checked {
        .ant-checkbox-inner {
          background-color: #9791BA;
          border-color: #9791BA !important;
        }

        &::after {
          border: none !important;
        }
      }
    }
  }

  .filter_form {
    display: flex !important;
    flex-wrap: wrap!important;
    row-gap: 1vw;
    margin-bottom: 1.55vw;
    @include flex-box(flex-start, center, row);

    .filter_item.infinite_list_sect {
      position: relative;
    }

    .filter_item {
      min-width: 12vw;
      max-width: 21.5vw;
      height: 3.2vw;
      border: 0.05vw solid $secondary-border-color;
      border-radius: 0.3vw;
      background: $grey-background-color;
      padding: 0 0.95vw;
      margin-right: 0.46vw;
      display: flex;
      @include flex-box(center, center, row);

      p {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 0.43vw;
        @include text-styles(0.85vw, 600, $role-color, left, $primary-font-family);
      }

      &:nth-of-type(1) {
        button.ant-dropdown-trigger {
          border: none;
          padding: 0;
          height: 1.4vw;
          background: transparent;
          width: 8vw;
          display: inline-flex;
          @include flex-box(space-between, center, row);
          @include text-styles(0.92vw, 500, $filter-form-placeholder-color, left, $primary-font-family);
        }
      }

      .ant-select {
        margin-left: 0 !important;
        height: 100%;

        .ant-select-selector {
          padding: 0;
          width: 100%;
          height: 100%;
          border: none;
          box-shadow: none;
          outline: none;
          background: transparent;
          display: flex;
          flex-wrap: nowrap;

          .ant-select-selection-search {
            width: 95% !important;
            margin-left: 0;

            .ant-select-selection-search-input {
              margin-left: 0;
              padding: 0;
            }
          }

          .ant-select-selection-placeholder {
            left: 0;
            top: 52%;
            line-height: 1.3;
            opacity: 1;
            @include text-styles(0.85vw, 500, $role-color, left, $primary-font-family);
          }

          .ant-select-selection-item {
            margin-top: 0;
            margin-bottom: 0;
            border-radius: 0.89vw;
            height: 1.7vw;
            background: $selected-item-background-color;
            padding: 0.2vw 0.5vw;
            border: 0.05vw solid $selected-item-border-color;
            line-height: normal;
            @include flex-box(space-between, center, row);

            .ant-select-selection-item-content {
              @include text-styles(0.89vw, 600, $filter-users-color, left, $primary-font-family);
            }

            .ant-select-selection-item-remove {
              background: $filter-remove-btn-color;
              border-radius: 50%;
              line-height: initial;
              display: flex;
              width: 16px;
              height: 16px;
              @include text-styles(0.6vw, 600, white, center, $primary-font-family);
              @include flex-box(center, center, row);
            }
          }
        }
      }

      &.user_select {
        .ant-select .ant-select-selector {
          .ant-select-selection-search {
            .ant-select-selection-search-input {
              min-width: 4vw;
              @include text-styles(0.85vw, 500, $user-profile-name-color, left, $primary-font-family);
            }
          }
          .ant-select-selection-item {
            .ant-select-selection-item-remove {
              font-size: 12px;
              display: flex;
              @include flex-box(center, center, row);

              > span {
                display: flex;
                @include flex-box(center, center, row);
              }
            }
          }
        }
      }

      &.deadline_select .ant-picker .ant-picker-input {
        .ant-picker-clear {
          right: 18%;
          background-color: #F3EFF9;
        }
      }
    }

    .ant-select {
      .ant-select-arrow {
        transition: transform .3s ease-in-out;
      }

      &.ant-select-open {
        .ant-select-arrow {
          transform: rotateX(180deg);
        }
      }
    }

    .close_filter_modal {
      cursor: pointer;
      font-size: 0.591vw;
      color: $hierarchy-btn-color;
      background-color: transparent;
      border: none;
      border-bottom: solid 0.5px $hierarchy-btn-color;
      margin-left: 1vw;
      padding: 0;
    }

    .deadline_select {
      .ant-picker, .ant-picker-focused {
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0;

        .ant-picker-input {
          input {
            @include text-styles(0.85vw, 500, $user-profile-name-color, left, $primary-font-family);

            &::placeholder {
              line-height: 1.3;
              @include text-styles(0.85vw, 500, $filter-form-placeholder-color, left, $primary-font-family);
            }
          }

          .ant-picker-suffix {
            margin-left: 0.5vw;
            svg {
              width: 1.6vw;
              height: 1.25vw;
            }
          }
        }
      }
    }

    .action_btn {
      white-space: nowrap;
      background: $select-button-background;
      border-radius: 5px;
      height: 3vw;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0 0.95vw;
      transition: background-color .2s ease-in-out;
      @include text-styles(0.85vw, 600, white, left, $primary-font-family);

      &:hover {
        background-color: $select-button-background-hover;
      }

      &:active {
        background-color: $select-button-background-active;
      }
    }
  }

  .color_picker {
    position: absolute !important;
    top: 85%;
    right: -14%;
    animation: 0.4s ease-in-out ZoomFadeOut;
    z-index: 99999;
  }

  .ant-picker-range {
    .ant-picker-active-bar {
      background-color: $select-button-background-hover;
    }
  }

  .ant-tabs {
    line-height: initial;
  }

  .ant-tabs {
    .ant-tabs-tab-disabled {
      color: rgba(0, 0, 0, 0.25) !important;

      .ant-tabs-tab-btn {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
    .ant-tabs-tab-disabled + .ant-tabs-ink-bar {
      background-color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      transition: color .3s ease-in-out;

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $tab-primary-color;
          font-weight: 700;
          transition: color .3s ease-in-out;
        }
      }
      .ant-tabs-tab-btn {
        color: inherit;
      }

      &:hover {
        color: inherit;
      }
    }
    .ant-tabs-ink-bar {
      background-color: $tab-primary-color;
      transition: background-color .3s ease-in-out;
    }
  }

  .shortened_url {
    text-decoration: underline !important;
    color: $btn-secondary-color !important;
    transition: color .2s ease-in-out;

    &:hover {
      color: $btn-secondary-color-hover !important;
    }
  }

  .ws_custom_status_bar {
    margin-bottom: 1vw;
    border: 1px solid $primary-border-color;
    width: 100%;
    height: 0.95vw;
    display: flex;
    @include flex-box(center, center, row);

    div {
      color: white;
      font-size: 0.7vw;
      height: 100%;
      display: flex;
      @include flex-box(center, center, row);

      &.accomplishment {
        background-color: $status-bar-progress-color;
      }

      &.plan {
        background-color: $on-track-indicator-color;
      }

      &.challenge {
        background: $status-bar-problems-color;
        border-right: 0;
      }
    }

    .bordered {
      border-right: 2px solid white;
    }
  }

  .progress_tooltip_custom {
    .ant-tooltip-inner {
      padding: 0.5vw 1.1vw;
      background: linear-gradient(123deg, $progress-bar-color-2 0, $progress-bar-color-1 100%);
      display: flex;
      @include flex-box(center, center, row);
      @include text-styles(0.92vw, 500, white, center, $primary-font-family);
    }

    .ant-tooltip-arrow {
      &:before {
        background: linear-gradient(123deg, $progress-bar-color-2 0, $progress-bar-color-1 100%);
      }
    }
  }

  .custom_checkbox_wrapper {
    margin: 0 !important;
    width: 39vw;
    padding: 0.76vw 2vw 0.76vw 2vw;
    display: flex;
    @include flex-box(flex-start, center, row);

    border-top: 0.05vw solid $primary-border-color;

    .ant-checkbox-inner {
      width: 1.04vw;
      height: 1.04vw;
      border: 0.1vw solid $secondary-border-color;

      &::after {
        left: 23.5%;
        width: 0.35vw;
        height: 0.55vw;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border: 0.1vw solid $switcher-active-border;
        background: $on-track-indicator-color;
      }
    }

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0.05vw solid $primary-border-color;
    }

    span {
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }

  .capitalized_list {
    text-transform: capitalize;
  }

  .tooltip_custom {
    word-break: break-word;

    .ant-tooltip-content {
      width: 100%;
    }
    .ant-tooltip-inner {
      padding: 0.8vw 1.3vw;
      background: $user-profile-name-color;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @include flex-box(center, center, row);
      @include text-styles(0.78vw, 500, white, center, $primary-font-family);

      pre {
        background: $user-profile-name-color;
        width: 100%;
        white-space: pre-wrap!important;
        @include text-styles(0.78vw, 500, white, center, $primary-font-family);
      }

      span {
        white-space: normal!important;
        overflow: auto!important;
        text-overflow: unset!important;
      }
    }

    .ant-tooltip-arrow {
      &:before {
        background: $user-profile-name-color;
      }
    }
  }

  .tooltip_info {
    line-height: initial;
    cursor: pointer;
    display: inline-block;
    border: 0.05vw solid $info-icon-background;
    border-radius: 50%;
    padding: 0 0.32vw;
    margin-left: 0.55vw;
    background-color: $info-icon-background;
    @include text-styles(0.7vw, 600, #ffffff, center, $primary-font-family);
  }

  .info {
    line-height: initial;
    cursor: pointer;
    display: inline-block;
    border: 0.05vw solid $secondary-border-color;
    border-radius: 50%;
    padding: 0 0.32vw;
    margin-left: 0.55vw;
    @include text-styles(0.7vw, 600, $role-color, center, $primary-font-family);
  }

  .tooltip_warning {
    line-height: initial;
    cursor: pointer;
    display: inline-block;
    border: 0.05vw solid $notification-warning-border;
    border-radius: 50%;
    padding: 0 0.32vw;
    background-color: white;
    @include text-styles(0.7vw, 600, $notification-warning-border, center, $primary-font-family);
  }

  .ant-tooltip-hidden {
    display: none !important;
  }

  .avatar {
    background-color: #ffffff;
  }

  .ws_status_indicator {
    margin-bottom: 1vw;
  }

  .specific_user_main_sect, .accomplishments_mine {
    .user_credentials_sect {
      margin-bottom: 0;
      display: flex;
      @include flex-box(space-between, center, row);

      .progress_bar {
        display: none;
      }

      div {
        display: inline-block;

        &:nth-of-type(1) {
          .specific_user {
            display: flex;
            @include flex-box(flex-start, center, row);

            img {
              margin-right: 0.45vw;
              @include img-styles(1.818vw, 1.818vw, cover, 50%);
            }

            div.avatar_skeleton {
              margin-right: 0.45vw;
              width: 1.818vw;
              height: 1.818vw;

              span {
                width: 1.818vw;
                height: 1.818vw;
              }
            }

            h2 {
              margin-bottom: 0;
              line-height: initial;
              @include text-styles(1.56vw, 500, $user-profile-name-color, left, $primary-font-family);
              max-width: 40vw;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        &:nth-of-type(2) {
          span {
            @include text-styles(0.82vw, 600, $user-profile-name-color, center, $primary-font-family);
          }
        }
      }
    }

    .adding_sect {
      margin-top: 1.5vw;
      display: flex;
      @include flex-box(space-between, center, row);
      padding: 1.05vw 1.05vw 1.05vw 1.64vw;
      background: $adding-sect-background;
      border: 0.05vw solid $adding-sect-border-color;
      border-radius: 0.5vw 0.5vw 0 0;
      border-bottom: 0.1vw solid $adding-sect-border-color;

      input.add-field {
        border: 0;
        outline: none;
        width: 65%;
        background: transparent;
        @include text-styles(0.85vw, 500, $adding-sect-input-color, left, $primary-font-family);

        &::placeholder {
          @include text-styles(0.85vw, 500, $adding-sect-input-color, left, $primary-font-family);
        }
      }

      button.cancel_btn {
        border-left: none;
        border-top: none;
        border-right: none;
        margin-left: 0.65vw;
      }
    }

    .actions_sect {
      display: flex;
      @include flex-box(flex-end, center, row);

      .advanced_actions {
        display: flex;
        @include flex-box(flex-end, center, row);

        .action_item {
          margin-left: 0.95vw;
          cursor: pointer;
          position: relative;
        }

        .fastener {
          @include icon-styles(1.05vw, 1.5vw, "icon-attachment-grey.svg", contain);
        }

        .goal {
          display: flex;
          @include flex-box(center, center, row);
          margin-bottom: 0;
          padding: 0;

          &.goal_attached {
            transition: background-color .2s ease-in-out;
            border-radius: 3.3px;
            background-color: #e2cef3 !important;
          }

          .goal_icon {
            @include icon-styles(1.5vw, 1.5vw, "icon-goal-grey.svg", contain);
          }
        }

        .emoji_selection {
          @include icon-styles(1.2vw, 1.2vw, "icon-smiley-grey.svg", auto);
        }

        .deadline {
          @include icon-styles(1.4vw, 1.4vw, "icon-timer-grey.svg", auto);
        }

        .deadline_title {
          cursor: pointer;
          margin-left: 0.5vw;
          white-space: nowrap;
          @include text-styles(0.7vw, 500, $user-profile-name-color, left, $primary-font-family);
        }

        .count {
          position: absolute;
          background: $count-indicator-background;
          border-radius: 50%;
          padding: 0.25vw 0.36vw;
          line-height: 1;
          right: -40%;
          top: -20%;
          @include text-styles(0.5vw, 600, white, center, $primary-font-family);
        }
      }

      button.add_accomplishment {
        line-height: 0.818vw;
        padding: 0.47vw 0.73vw;
        background: $review-btn-color;
        outline: none;
        border: none;
        cursor: pointer;
        transition-duration: 0.4s;
        margin-left: 0.95vw;
        transition: 0.4s;

        &:hover {
          background: $review-btn-color-hover;
        }

        &:active {
          background: $review-btn-color-active;
        }

        border-radius: 0.3vw;
        @include text-styles(0.77vw, 600, white, left, $primary-font-family);
      }
    }

    .ws_single_sect {
      background: white;
      box-shadow: $box-shadow-secondary;
      border: 1px solid $primary-border-color;
      border-radius: 0.3vw;
      margin-bottom: 1.4vw;
      transition: box-shadow .3s ease-in-out;

      &_dragged {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
      }

      .ws_byUser_items_loader {
        margin-bottom: 1vw;
      }


      .timeline_list {
        li {
          flex-direction: row !important;
        }
      }
    }

    .ws_progress {
      .title_icon {
        @include icon-styles(1vw, 0.9vw, "icon-weekly-accomplishment.svg", cover);
      }
    }

    .ws_plans {
      margin-top: 2.16vw;

      .title_icon {
        @include icon-styles(1vw, 0.9vw, "icon-weekly-plan.svg", cover);
      }
    }

    .ws_coming_up {
      .title_icon {
        @include icon-styles(1vw, 0.9vw, "weekly-coming-up.svg", cover);
      }
    }

    .ws_challenges {
      .title_icon {
        margin-right: 0.5vw;
        @include icon-styles(1vw, 0.9vw, "icon-weekly-problem.svg", cover);
      }
    }

    .week_satisfaction_sect {
      margin-top: 1.4vw;
      padding: 2.3vw 0 3vw 2.75vw;

      h3 {
        @include text-styles(1.14vw, 500, $user-profile-name-color, left, $primary-font-family);
      }

      div.satisfaction_rate_main {
        display: flex;
        @include flex-box(flex-start, center, row);

        .checked_stars_count {
          @include text-styles(1.59vw, 500, $user-profile-name-color, left, $primary-font-family);
        }
      }
    }

    .section_heading {
      display: flex;
      align-items: center;
      padding: 2.23vw 0 2.23vw 1.27vw;
      @include text-styles(1.14vw, 500, $user-profile-name-color, left, $primary-font-family);

      .title_icon {
        min-width: 1vw;
        min-height: 1vw;
      }

      p {
        margin: 0;
      }
    }
  }

  .accomplishments_mine, .accomplishments_mine {
    .user_credentials_sect {
      margin-bottom: 1.4vw;
    }
  }

  .left_right_padding_sect {
    padding: 0 2.86vw;
  }

  .interactive_sub_list {
    list-style: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid $primary-border-color;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      padding: 0.83vw 1.1vw 0.83vw 1.69vw;
      background: $secondary-menu-item-color;
      display: flex;
      @include flex-box(space-between, center, row);

      &.attached_goal_sect {
        word-break: break-word;
      }

      &:nth-of-type(1) {
        border-top: 1px solid $primary-border-color;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      .subItem_content {
        display: flex;
        @include flex-box(flex-start, center, row);

        & > div {
          display: flex;
          @include flex-box(flex-start, center, row);

          img {
            margin-right: 0.5vw;
            @include img-styles(1.5vw, 1.5vw, cover, 50%);
          }

          p {
            margin-bottom: 0;
            cursor: pointer;
            @include text-styles(0.87vw, 500, $user-profile-name-color, left, $primary-font-family);
          }
        }
      }
    }
  }

  .interactive_sub_list.closed {
    display: none;
  }

  .nested_tabs {
    .ant-tabs-tab {
      margin: 0 0.5vw;
      padding: 0.55vw 0.46vw 1.7vw 0.46vw;
      letter-spacing: 0;
      @include text-styles(0.82vw, 400, $role-color, left, $primary-font-family);

      &:first-child {
        margin: 0 0.5vw 0 0;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: $tab-primary-color;
      }
    }
  }

  .page_control_panel {
    position: relative;

    h1 {
      position: absolute;
      top: 0.5vw;
      z-index: 1;
      display: flex;
      @include flex-box(center, center, row)
    }

    .weekly_status_tabs {
      .ant-tabs-nav {
        border-bottom: 1px solid $primary-border-color;
        margin-bottom: 1.7vw;
      }

      .ant-tabs-tab {
        margin: 0 0.5vw;
        padding: 0.55vw 0.46vw 1.7vw 0.46vw;
        letter-spacing: 0;
        @include text-styles(0.82vw, 400, $role-color, left, $primary-font-family);

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background-color: $tab-primary-color;
        }
      }

      .ant-tabs-tab-active {
        @include text-styles(0.82vw, bold, $tab-primary-color, left, $primary-font-family);
      }

      .ant-tabs-ink-bar {
        background-color: $tab-primary-color;
      }

      .ant-tabs-tabpane {
        opacity: 0;

        & > div {
          display: none !important;
        }
      }

      .ant-tabs-tabpane-active {
        opacity: 1;

        & > div {
          display: flex !important;
        }
      }
    }

    .hidden_tabs {
      visibility: hidden;
    }

    .ant-tabs {
      overflow: inherit;
    }
  }
  .weekly_status {
    .interactive_list_item {
      .interactive_sub_list {
        .comments_list li {
          flex-direction: row;
          span {
            @include text-styles(0.69vw, 500, $role-color, left, $primary-font-family);
          }
        }
      }
    }
  }
  p.go_back_btn {
    position: relative;
    display: inline-block;
    border-bottom: 0.05vw solid $check-in-creator-color;
    cursor: pointer;
    margin-bottom: 0.8vw;
    transition: all 0.2s ease-in-out;
    @include text-styles(0.82vw, 500, $check-in-creator-color, left, $primary-font-family);

    &:hover {
      border-bottom-color: $check-in-creator-color-hover;
      color: $check-in-creator-color-hover;
    }

    .left_arrow {
      margin-right: 0.3vw;
    }
  }

  button.cancel_btn {
    outline: none;
    padding: 0;
    background: transparent;
    border-bottom: 0.05vw solid $check-in-creator-color;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    @include text-styles(0.72vw, 500, $check-in-creator-color, left, $primary-font-family);

    &:hover {
      border-bottom: 0.05vw solid $check-in-creator-color-hover;
      color: $check-in-creator-color-hover;
    }
  }

  div.single_sect {
    background: white;
    box-shadow: $box-shadow-secondary;
    border: 0.05vw solid $primary-border-color;
    border-radius: 0.3vw;
    margin-bottom: 1.4vw;
    padding: 2.75vw;

    &_last {
      order: 1;
    }
  }

  div.secondary_single_list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .single_sect_secondary_text {
      word-break: break-word;
    }
  }
  div.secondary_single_sect {
    background: white;
    box-shadow: $box-shadow-secondary;
    border: 0.05vw solid $primary-border-color;
    border-radius: 0.3vw;
    margin-bottom: 1.4vw;
    padding: 0;

    &_last {
      order: -1;
    }
  }

  h3.secondary_single_sect_primary_text {
    line-height: 1;
    margin-bottom: 0;
    padding: 2.75vw 2.75vw 0 2.75vw;
    @include text-styles(1.14vw, 500, $user-profile-name-color, left, $primary-font-family);
  }

  .secondary_single_sect_description_primary_text {
    padding: 0.5vw 2.75vw 0;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    word-break: break-word;
    @include text-styles(0.82vw, 500, $role-color, left, $primary-font-family);
  }

  h3.single_sect_primary_text {
    line-height: 1;
    margin-bottom: 0;
    @include text-styles(1.14vw, 500, $user-profile-name-color, left, $primary-font-family);

    sup {
      margin-left: 0.22vw;
      color: red;
    }
  }

  .enps_sup {
    top: -0.15vw;
  }

  p.single_sect_secondary_text {
    margin-top: 0.25vw;
    @include text-styles(0.75vw, 500, $role-color, left, $primary-font-family);
  }

  div.input_field {
    margin-top: 1.8vw;
    display: flex;
    @include flex-box(space-between, center, row);
    padding: 1.05vw 1.64vw 1.05vw 1.64vw;
    background: $adding-sect-background;
    border: 0.05vw solid $adding-sect-border-color;
    border-radius: 0.5vw 0.5vw 0 0;
    border-bottom: 0.1vw solid $adding-sect-border-color;

    input, textarea {
      resize: none;
      border: 0;
      outline: none;
      width: 100%;
      background: transparent;
      @include text-styles(0.8vw, 500, $user-profile-name-color, left, $primary-font-family);

      &::placeholder {
        @include text-styles(0.8vw, 500, $adding-sect-input-color, left, $primary-font-family);
      }
    }

    &_disabled {
      box-shadow: $box-shadow-secondary;
      border-color: $adding-sect-border-color;
      background-color: $inactive-item-background;
      opacity: 0.6;
      cursor: not-allowed;
      user-select: none;

      input, textarea {
        cursor: not-allowed;
        user-select: none;
      }
    }
  }
  .input_field_validation {
    margin: 0;
    @include text-styles(0.8vw, 600, $status-bar-problems-color, left, $primary-font-family);
  }

  .satisfaction_rate_sect {
    color: $rate-stars-color;
    margin-right: 0.7vw;

    li.ant-rate-star {
      margin-right: 0.4vw;
      font-size: 1.47vw;
    }
  }

  .toggle {
    height: 0.75vw;
    min-width: 3vw;
    background-color: $primary-border-color;

    .ant-switch-handle {
      width: 1.4vw;
      height: 1.4vw;
      top: -0.33vw;
      margin-left: -0.2vw;
      border-radius: 50%;

      &::before {
        border-radius: 50%;
        background-color: $secondary-border-color;
      }
    }
  }

  .toggle.ant-switch-checked {
    background-color: #c9d3fc;

    .ant-switch-handle {
      margin-left: 0.2vw!important;

      &::before {
        background-color: $on-track-indicator-color;
      }
    }
  }

  .primary_action {
    display: flex;
    @include flex-box(center, center, row);
    border: none;
    outline: none;
    transition-duration: 0.4s;
    background-color: $review-btn-color;
    border-radius: 0.3vw;
    padding: 0.92vw 1.1vw;
    cursor: pointer;
    transition: background-color .2s ease-in-out, opacity .2s ease-in-out;
    @include text-styles(0.72vw, 600, white, left, $primary-font-family);

    &.primary_action_disabled {
      background-color: $review-btn-disabled-color;
      cursor: default;
      pointer-events: none;

      &:active {
        box-shadow: none;
        background-color: $review-btn-disabled-color;
      }
    }

    &:hover {
      background-color: $review-btn-color-hover;
    }

    &:active {
      background-color: $review-btn-color-active;
    }
  }

  .button_loading {
    opacity: 0.6;
    cursor: default;
  }

  button.secondary_action {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid $check-in-creator-color;
    transition: all 0.2s ease-in-out;
    @include text-styles(0.78vw, 600, $check-in-creator-color, center, $primary-font-family);

    &:hover {
      border-bottom-color: $check-in-creator-color-hover;
      color: $check-in-creator-color-hover;
    }
  }

  .default_outlined_btn {
    border: 0.05vw solid $tab-primary-color;
    border-bottom-width: 0.15vw;
    cursor: pointer;
    outline: none;
    border-radius: 0.25vw;
    background: white;
    min-height: 1.69vw;
    display: flex;
    padding: 0 0.64vw;
    transition: background-color 0.2s ease-in-out, opacity .2s ease-in-out;
    @include text-styles(0.64vw, 600, $tab-primary-color, center, $primary-font-family);
    @include flex-box(center, center, row);

    &:hover {
      background-color: $tab-primary-color-hover-bg;
    }

    &:active {
      background-color: $tab-primary-color-active-bg;
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
      user-select: none;

      &:hover, &:active {
        background-color: #ffffff !important;
      }
    }
  }

  .save_btn {
    margin-top: 3.2vw;
    border-radius: 0.2vw;
    padding: 1.5vw 2.1vw;
  }

  .advanced_actions_menu {
    background: $dropdown-background-color !important;
    box-shadow: $box-shadow-secondary !important;
    border-radius: 0.3vw;
    position: relative;

    li {
      padding-left: 1.2vw;
      padding-right: 1.2vw;

      span {
        @include text-styles(0.72vw, 500, white, left, $primary-font-family);

        span {
          margin-right: 0.2vw !important;
        }
      }

      &:not(:last-child) {
        border-bottom: 0.1vw solid $dropdown-menu-item-border-color;
      }
    }

    li.ant-dropdown-menu-item:hover {
      background-color: transparent !important;
    }
    li.ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }

    &:before {
      width: 0;
      height: 0;
      content: ' ';
      position: absolute;
      top: -0.25vw;
      left: calc(50% - 0.28vw);
      border-left: 0.3vw solid transparent;
      border-right: 0.3vw solid transparent;
      border-bottom: 0.3vw solid $dropdown-background-color
    }
  }

  .total_count {
    @include text-styles(0.7vw, 500, #ffffff, center, $primary-font-family);
    background-color: #b19bd6;
    padding: 0.25vw 0.5vw;
    border-radius: 3.3px;
    margin-right: 1.5vw;
  }

  /// animation styles

  .animation-enter {
    opacity: 0;
  }

  .animation-exit {
    opacity: 1;
  }

  .animation-enter-active {
    opacity: 1;
  }

  .animation-exit-active {
    opacity: 0;
  }

  .animation-enter-active,
  .animation-exit-active {
    transition: opacity 400ms;
  }

  @keyframes ZoomFadeOut {
    from {
      transform: scale(0, 0);
      opacity: 0
    }
    to {
      transform: scale(1, 1);
      opacity: 1
    }
  }
  ///

  .hidden_datepicker_input {
    display: block;
    height: 0;
    width: 0;
    visibility: hidden;
    padding: 0;
  }

  //// form item styles in modals
  .form_item {
    display: flex;
    margin-bottom: 1vw;
    position: relative;
    width: 18.8vw;
    @include flex-box(center, flex-start, column);

    label {
      line-height: 1;
      margin-bottom: 0.47vw;
      @include text-styles(0.8vw, 500, $user-profile-name-color, left, $primary-font-family);

      sup {
        top: -0.1em;
        left: 0.2em;
        @include text-styles(1vw, 500, $status-bar-problems-color, left, $primary-font-family); //need to change
      }
    }

    .goal_input,
    .field_input,
    .team_input,
    .user_input,
    .people_input,
    .login_input,
    .selected_form_template,
    .password_input {
      min-height: 3.5vw;
      line-height: 1;
      width: 100%;
      padding: 0.94vw 0.98vw;
      outline: none;
      background: $adding-sect-background;
      border: 0.05vw solid $adding-sect-border-color;
      border-bottom: 0.1vw solid $adding-sect-border-color;
      border-radius: 0.3vw 0.3vw 0 0;
      @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);

      &::placeholder {
        @include text-styles(0.8vw, 500, $adding-sect-input-color, left, $primary-font-family);
      }
    }

    .goal_input.description {
      resize: none;
      height: 5.6vw;
    }

    .ant-picker {
      width: 100%;
      min-height: 3.4vw;
      background: $adding-sect-background;
      border: 0.05vw solid $adding-sect-border-color;
      border-bottom: 0.1vw solid $adding-sect-border-color;
      border-radius: 0.3vw 0.3vw 0 0;
      padding: 0.94vw 0.98vw;

      .ant-picker-input {
        position: relative;

        input {
          padding-left: 1.7vw;
          border: none;

          &::placeholder {
            @include text-styles(0.8vw, 500, $adding-sect-input-color, left, $primary-font-family);
          }

          @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family); //need to change
        }

        .ant-picker-suffix {
          position: absolute;
          left: 0;
          margin-left: 0;

          img {
            width: 1.3vw;
          }
        }
      }
    }

    .form_input {
      min-height: 3.4vw;
      background: #EBE7F533;
      border: 0.05vw solid #b19bd6;
      border-bottom: 0.1vw solid #b19bd6;
      border-radius: 0.3vw 0.3vw 0 0;
      padding: 0.65vw 0.98vw;
      width: 100%;
      outline: none;
    }

    .ant-select-disabled {
      .ant-select-selector {
        opacity: 0.4!important;
      }
    }

    .ant-select {
      width: 100%;
      line-height: 1;
      @include text-styles(0.95vw, 500, $user-profile-name-color, left, $primary-font-family); //need to change
      .ant-select-selector {
        min-height: 3.4vw;
        background: $adding-sect-background;
        border: 0.05vw solid $adding-sect-border-color;
        border-bottom: 0.1vw solid $adding-sect-border-color;
        border-radius: 0.3vw 0.3vw 0 0;
        padding: 0.65vw 0.98vw;

        .ant-select-selection-placeholder {
          display: flex;
          opacity: 1;
          @include flex-box(flex-start, center, row);
          @include text-styles(0.8vw, 500, $adding-sect-input-color, left, $primary-font-family);
        }

        .ant-select-selection-item {
          display: flex;
          @include flex-box(flex-start, center, row);
          @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }

    .error_sect {
      padding-top: 0.15vw;
      transition: all .3s ease-in-out;
      opacity: 0;
      @include text-styles(0.69vw, 600, transparent, left, $primary-font-family);
    }
  }

  .form_item.invalid, .form_parent.invalid {
    input.goal_input,
    .people_input,
    .field_input,
    .login_input,
    .user_input,
    .team_input,
    .form_input,
    .description,
    .password_input,
    .ant-select .ant-select-selector, .ant-picker {
      border-color: $status-bar-problems-color;
      background: $invalid-field-background;
    }

    .error_sect {
      opacity: 1 !important;
      margin-bottom: 0;
      @include text-styles(0.69vw, 600, $status-bar-problems-color, left, $primary-font-family);
    }
  }

  .form_item.with_bottom_space {
    margin-bottom: 2vw;
  }

  /////

  .weekly_item_created_date {
    position: absolute;
    bottom: 0.35vw;
    margin: 0;
    @include text-styles(0.72vw, 500, $secondary-text-color, center, $primary-font-family);
  }

  //// specific page header

  .user_credentials_sect {
    margin-bottom: 1.8vw;
    display: flex;
    @include flex-box(space-between, center, row);

    .user_info {
      display: flex;
      width: max-content;
      @include flex-box(flex-start, center, row);

      h2 {
        margin-bottom: 0;
        line-height: normal;
        margin-left: 0.5vw;
        @include text-styles(1.55vw, 500, $user-profile-name-color, left, $primary-font-family);
      }

      img {
        @include img-styles(1.909vw,1.909vw, cover, 50%);
      }

      div.avatar_skeleton, div.avatar_skeleton span {
        width: 1.909vw;
        height: 1.909vw;
      }

      .team_badge {
        text-transform: capitalize;
        border-radius: 0.2vw;
        padding: 0.25vw 0.3vw;
        line-height: 1;
        margin-left: 0.6vw;
        background: $primary-border-color; // set by default
        border: 0.05vw solid #B0A4C6; // set by default
        @include text-styles(0.72vw, 500, $role-color, left, $primary-font-family); // set by default
      }
    }
  }

  ////

  //// goals status section styles

  .goals_status_info_main {
    display: flex;
    border-bottom: 0.01vw solid $primary-border-color;
    padding-bottom: 1vw;
    margin-bottom: 1.1vw;
    @include flex-box(flex-start, center, row);

    .goals_status_list {
      list-style: none;
      padding: 0;
      margin-bottom: 0.7vw;
      display: flex;
      @include flex-box(flex-start, center, row);

      li {
        position: relative;
        margin-right: 2.28vw;
        display: flex;
        z-index: 1;
        @include flex-box(flex-start, center, row);

        .goal_count {
          position: relative;
          margin-left: 0.55vw;
          line-height: 1;
          padding-right: 0.7vw;
          @include text-styles(1.4vw, 500, $user-profile-name-color, left, $primary-font-family);

          span.indicator {
            position: absolute;
            right: 0;
            top: 0.1vw;
            width: 0.27vw;
            height: 0.27vw;
            border-radius: 50%;
          }
        }

        p {
          margin-bottom: 0;
          margin-left: 0.4vw;
          margin-top: 0.6vw;
          line-height: 1;
          @include text-styles(0.69vw, 500, $role-color, left, $primary-font-family);

          &:first-letter {
            text-transform: uppercase;
          }
        }

        &.onTrack {
          .indicator {
            background-color: $on-track-indicator-color;
          }
        }

        &.atRisk {
          .indicator {
            background-color: $at-risk-indicator-color;
          }
        }

        &.offTrack {
          .indicator {
            background-color: $off-track-indicator-color;
          }
        }

        &.exceeded {
          .indicator {
            background-color: $exceeded-indicator-color;
          }
        }

        &.completed {
          .indicator {
            background-color: $completed-item-border-color;
          }
        }

        .activity_indicator {
          position: absolute;
          top: -13.5%;
          right: -5%;
          width: 0.85rem;
          height: 0.85rem;
          transform-origin: center;
          transform: scale(0, 0);
          border-radius: 50%;
          opacity: 0;
          background: $status-bar-progress-color;
        }
      }

      li.sort_able {
        min-height: 62px !important;
        border: 1px solid transparent;
        cursor: pointer;
        padding: 0.35vw;

        &:hover {
          border-radius: 0.2vw;
          border: 1px solid $adding-sect-border-color;
          box-shadow: $box-shadow-selected;
        }
      }

      li.sort_able.active {
        border-radius: 0.3vw;
        border: 1px solid $adding-sect-border-color;
        box-shadow: $box-shadow-selected;

        .activity_indicator {
          transition: 0.4s;
          opacity: 1;
          transform: scale(1.2, 1.2);
        }
      }
    }
  }

  .skeleton_container {
    padding-left: 0;
    list-style: none;
    display: flex;
    border-bottom: 0.05vw solid $primary-border-color;
    padding-bottom: 2vw;
    margin-bottom: 1vw;
    @include flex-box(flex-start, center, row);

    li {
      display: flex;
      margin-right: 2.28vw;
      @include flex-box(flex-start, center, row);

      .ant-skeleton {
        &:first-child {
          margin-right: 1vw;
        }
      }
    }
  }

  .goals_progress_bar {
    width: 100%;
    display: flex;
    @include flex-box(flex-start, center, row);
    height: 0.35vw;
    border-radius: 0.7vw;
    background-color: $primary-border-color;
    margin-bottom: 1vw;

    div {
      height: 100%;
      border-bottom-right-radius: 0.7vw;
      border-top-right-radius: 0.7vw;
    }

    .on_track_section {
      border-bottom-left-radius: 0.7vw;
      border-top-left-radius: 0.7vw;
      background: transparent linear-gradient(90deg, $on-track-indicator-color 0, $on-track-secondary-indicator 100%) 0 0 no-repeat padding-box;
    }

    .at_risk_section {
      background-color: $at-risk-indicator-color;
    }

    .off_track_section {
      background-color: $off-track-indicator-color;
    }

    .exceeded_section {
      background-color: $exceeded-indicator-color;
    }

    .completed_section {
      background-color: $completed-indicator-color;
    }
  }

  ////

  //// WS charts styles

  .charts_container {
    display: flex;
    @include flex-box(center, center, row);
  }

  .goals_charts_section, .pulse_point_charts_section, .statistics_chart {
    width: max-content;
    background: white 0 0 no-repeat padding-box;
    box-shadow: $box-shadow-secondary;
    border-radius: 0.3vw;
    border: 1px solid $primary-border-color;
    display: flex;
    @include flex-box(center, stretch, row);

    .chart_tile {
      border-right: 0.05vw solid $primary-border-color;
      flex: 1;
      padding-top: 1.82vw;
      display: flex;
      width: 16.05vw;
      box-shadow: 0 0 15px 0 #CEB9E829;
      @include flex-box(space-between, flex-start, column);

      .chart_info_sect, .pulse_point_charts_section {
        padding-left: 2.1vw;
        margin-bottom: 0.8vw;

        p {
          line-height: 1;
          margin-bottom: 0;
          position: relative;
          @include text-styles(0.87vw, 500, $user-profile-name-color, left, $primary-font-family);

          .ws_icon {
            position: absolute;
            background-size: cover !important;
            width: 0.75vw;
            height: 0.75vw;
            left: -1.2vw;
          }
        }

        span {
          display: block;
          @include text-styles(0.8vw, 500, $role-color, left, $primary-font-family);
        }

        .percentage_value {
          margin-top: 0.75vw;
          line-height: 1;
          @include text-styles(1.6vw, 500, $user-profile-name-color, left, $primary-font-family);
        }
      }

      &:last-of-type {
        border-right: none;
      }

      .chart_sect {
        height: 3.45vw;
        width: 100%;
      }
    }

    .accomplishment_tile {
      .ws_icon {
        background: url("../../assets/images/icons/icon-weekly-accomplishment.svg") no-repeat;
      }
    }

    .weekly_plans_tile {
      .ws_icon {
        background: url("../../assets/images/icons/icon-weekly-plan.svg") no-repeat;
      }
    }

    .problems_tile {
      .ws_icon {
        width: 1vw !important;
        height: 0.9vw !important;
        left: -1.3vw !important;
        background: url("../../assets/images/icons/icon-weekly-problem.svg") no-repeat;
      }
    }
  }

  ////

  //// Already reviewed reviews page

  .check_icon {
    position: absolute;
    bottom: 0vw;
    right: 0.1vw;
    width: 0.75vw;
    height: 0.75vw;
    background: $exceeded-indicator-color;
    border-radius: 50%;
    user-select: none;
    transform: rotateZ(52deg) rotateY(180deg);
    border: 0.1vw solid white;
    display: flex;
    @include flex-box(center, center, row);
    @include text-styles(0.5vw, 600, white, center, $primary-font-family);
  }

  .reviewed_users_info {
    display: flex;
    @include flex-box(space-between, center, row);

    .reviewed_users_list {
      list-style: none;
      padding: 0;
      display: flex;
      margin-bottom: 0;
      margin-right: 0.95vw;
      @include flex-box(flex-start, center, row);

      li {
        transition-duration: 0.4s;
        border: 0.1vw solid transparent;
        position: relative;
        border-radius: 50%;
        width: 1.9vw;
        height: 1.9vw;
        display: flex;
        @include flex-box(center, center, row);

        img {
          @include img-styles(1.28vw, 1.28vw, cover, 50%);
        }

        &:hover {
          border: 0.1vw solid $avatar-border-color;
        }
      }
    }

    .details_btn {
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
      text-transform: uppercase;
      padding: 0 0 0.13vw 0;
      line-height: 1;
      border-bottom: 0.05vw solid $btn-secondary-color;
      transition: all 0.2s ease-in-out;
      @include text-styles(0.69vw, 600, $btn-secondary-color, center, $primary-font-family);

      &:hover {
        border-bottom-color: $btn-secondary-color-hover;
        color: $btn-secondary-color-hover;
      }
    }

    .details_btn.with_bottom_gap {
      margin-bottom: 1.5vw;
    }
  }

  .reviews {
    &.review {
      div {
        .single_sect {
          h4 {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            max-width: 75vw;
          }
        }
      }
    }
  }

  .rate_with_explanation, .rating-field {
    .hidden_input_field.show {
      margin-top: 2.6vw;
      transition: 0.3s ease-in-out;
      display: flex;
      height: 4vw;
      opacity: 1;
      padding: 0 1.64vw;
      @include flex-box(flex-start, center, row);

      input {
        height: 40%;
        outline: none;
      }
    }

    .hidden_input_field.hide {
      transition: 0.3s ease-in-out;
      border: 0 solid transparent;
      height: 0;
      padding: 0 1.64vw;
      margin-top: 0;
      opacity: 0;
      display: none;
      input {
        height: 40%;
        display: none;
      }
    }

    .selected {
      position: absolute;
      top: -0.5vw;
      content: ' ';
      width: 0;
      height: 0;
      border-left: 0.5vw solid transparent;
      border-right: 0.5vw solid transparent;
      border-bottom: 0.5vw solid $adding-sect-border-color;
    }

    .custom_rate_list {
      list-style: none;
      padding-left: 0;
      display: flex;
      min-height: 5vw;
      margin-top: 1.2vw;
      @include flex-box(flex-start, center, row);

      li {
        text-transform: capitalize;
        padding: 0 1.82vw;
        height: 4.1vw;
        background: $adding-sect-background;
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        border: 0.1vw solid $adding-sect-border-color;
        border-bottom: 0.15vw solid $adding-sect-border-color;
        @include flex-box(center, center, row);
        @include text-styles(0.82vw, 500, $adding-sect-input-color, center, $primary-font-family);

        &:not(:last-child) {
          border-right: 0;
        }

        &:hover {
          background: white;
          height: 4.55vw;
          box-shadow: $box-shadow-selected;
          border-right: 0.1vw solid $adding-sect-border-color;
          @include text-styles(0.95vw, 500, $user-profile-name-color, center, $primary-font-family);
        }

        &:last-child {
          border-top-right-radius: 0.3vw;
        }

        &:first-child {
          border-top-left-radius: 0.3vw;
        }
      }

      li.active {
        background: white;
        height: 4.55vw;
        box-shadow: $box-shadow-selected;
        border: 0.1vw solid $active-item-color;
        border-bottom: 0.15vw solid $active-item-color;
        border-radius: 0.2vw 0.2vw 0 0;
        @include text-styles(0.95vw, 500, $user-profile-name-color, center, $primary-font-family);
      }
    }

    .selectedLevel-insufficient:before {
      left: 3.6vw;
      top: -0.6vw;
      @extend .selected;
    }

    .selectedLevel-poor:before {
      left: 10.2vw;
      top: -0.6vw;
      @extend .selected;
    }

    .selectedLevel-fair:before {
      left: 15.4vw;
      top: -0.6vw;
      @extend .selected;
    }

    .selectedLevel-good:before {
      left: 20.8vw;
      top: -0.6vw;
      @extend .selected;
    }

    .selectedLevel-excellent:before {
      left: 27.2vw;
      top: -0.6vw;
      @extend .selected;
    }
  }

  .submit_btn {
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 0.6vw;
    border-radius: 0.3vw;
    padding: 1.5vw 2.1vw;
    background-color: $review-btn-color;
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
    @include text-styles(0.78vw, 600, white, center, $primary-font-family);

    &:hover {
      background-color: $review-btn-color-hover;
    }

    &:active {
      background-color: $review-btn-color-active;
    }
  }

  .details_sect {
    transition-duration: 0.4s;

    .details_list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0.74vw 0;
        border-top: 0.05vw solid $primary-border-color;
        display: flex;
        @include flex-box(space-between, center, row);

        div {
          &:nth-of-type(1) {
            display: flex;
            @include text-styles(0.82vw, 500, $user-profile-name-color, center, $primary-font-family);
            @include flex-box(flex-start, center, row);

            div {
              margin-right: 0.74vw;
              position: relative;

              img {
                @include img-styles(1.28vw, 1.28vw, cover, 50%);
              }

              .check_icon {
                bottom: 0;
                right: -0.25vw;
              }
            }

            .relationship-badge {
              border-radius: 0.15vw;
              line-height: 1;
              padding: 0.2vw 0.25vw;
              text-transform: uppercase;
              margin-left: 0.64vw;
              border: 0.05vw solid #A277C7; //set by default
              @include text-styles(0.55vw, 500, #A277C7, center, $primary-font-family) //set by default;
            }
          }

          &:nth-of-type(2) {
            @include text-styles(0.76vw, 500, $role-color, center, $primary-font-family);
          }
        }

        &:last-child {
          border-bottom: 0.05vw solid $primary-border-color;
        }
      }
    }
  }

  .details_sect.show {
    height: 100%;
    opacity: 1;
    margin-bottom: 2vw;

    .details_list {
      li {
        height: 100%;
        opacity: 1;

        div {
          height: 100%;
          opacity: 1;
        }
      }
    }
  }

  .details_sect.hide {
    height: 0;
    opacity: 0;
    margin-bottom: 0;
    display: none;
    .details_list {
      height: 0;
      opacity: 0;

      li {
        height: 0;
        opacity: 0;
        padding: 0;

        div {
          height: 0;
          opacity: 0;
        }
      }
    }
  }

  .reviews_list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    margin-top: 2vw;

    li.list_item {
      padding: 1.2vw 2.75vw;
      display: flex;
      border-top: 0.05vw solid $primary-border-color;
      @include flex-box(flex-start, center, row);

      .tooltip_inner {
        margin-right: 0.6vw;
        img {
          margin-right: 0;
        }
      }

      img {
        margin-right: 0.6vw;
        @include img-styles(1.28vw, 1.28vw, cover, 50%);
      }

      div.avatar_skeleton {
        width: 1.28vw;
        margin-right: 0.6vw;

        span {
          width: 1.28vw;
          height: 1.28vw;
        }
      }

      .short_badge {
        line-height: 1;
        padding: 0.25vw 0.2vw;
        border-radius: 0.1vw;
        display: flex;
        text-transform: uppercase;
        margin-right: 0.6vw;
        border: 0.05vw solid #A277C7; //set by default
        @include flex-box(flex-start, center, row);
        @include text-styles(0.6vw, 600, #A277C7, center, $primary-font-family); //set by default
      }

      p {
        margin-bottom: 0;
        margin-right: 0.5vw;
        word-break: break-word;
        width: 100%;
        line-height: initial;
        @include text-styles(0.96vw, 500, $user-profile-name-color, left, $primary-font-family);
      }

      .rate-main-item-content {
        display: flex;
        width: 100%;
        padding: 1.2vw 2.75vw;
        @include flex-box(space-between, center, row);

        div {
          &:nth-of-type(1) {
            display: flex;
            @include flex-box(flex-start, center, row);
          }
        }

        .explanation_icon {
          cursor: pointer;
          @include img-styles(1.6vw, 1.4vw, cover, 0);
          background: url("../../assets/images/icons/icon-comment.svg") no-repeat;
        }

        .levelProgress_sect {
          width: 16vw;
          margin-right: 0.7vw;
          display: flex;
          @include flex-box(center, center, row);
        }
      }
    }

    .list_item.interactive_list_item {
      flex-direction: column !important;
      padding: 0 !important;

      ul.interactive_sub_list {
        width: 100%;
        display: flex;
        @include flex-box(center, center, column);

        li {
          padding: 1vw 2.75vw;
          display: inline-block;
          width: 100%;
          word-break: break-word;
          @include text-styles(0.82vw, 500, $role-color, left, $primary-font-family);
        }
      }

      ul.interactive_sub_list.closed {
        display: none;
      }
    }

    .satisfaction_rate_sect {
      font-size: unset;
      line-height: 1;
      margin-right: 1vw;

      li.ant-rate-star {
        font-size: 1.3vw;

        & > div {
          line-height: 1;
        }
      }
    }

    span.grade_indicator, span.level_indicator {
      @include text-styles(0.95vw, 500, $user-profile-name-color, left, $primary-font-family);
    }
  }

  .overall_sect {
    display: flex;
    padding: 1vw 2.75vw 0 2.75vw;
    @include flex-box(flex-start, center, row);

    &.overall_result {
      padding-bottom: 2vw;
    }

    p {
      margin-bottom: 0;
      margin-right: 1.01vw;
      white-space: nowrap;
    }

    span.grade_indicator {
      @include text-styles(1.05vw, 500, $user-profile-name-color, left, $primary-font-family);
      margin-left: 1vw;
    }

    .ant-progress {
      font-size: 6px;

      .ant-progress-inner {
        .ant-progress-bg {
          height: 6px;
        }
      }
    }
  }

  ////

  //// Timeline section styles

  .timeline_list {
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.23vw;
      box-shadow: $box-shadow-secondary;
      border: 0.05vw solid $primary-border-color;
      border-radius: 0.2vw;
      padding: 1.28vw;
      background: white;
      display: flex;
      @include flex-box(space-between, center, row);

      .short_info {
        display: flex;
        @include flex-box(flex-start, center, row);

        .img_container {
          margin-right: 0.5vw;
          display: flex;
          width: 1vw;
          height: 1vw;
          @include flex-box(center, center, row);

          img {
            @include img-styles(1vw, 1vw, cover, 50%);
          }

          div.avatar_skeleton {
            width: 1vw;
            height: 1vw;

            span {
              width: 1vw;
              height: 1vw;
            }
          }
        }

        p {
          margin-bottom: 0;
          white-space: nowrap;
          @include text-styles(0.72vw, 500, $role-color, center, $primary-font-family);

          &:first-letter {
            text-transform: capitalize;
          }
        }

        h4 {
          cursor: pointer;
          margin-right: 0.5vw;
          margin-bottom: 0;
          white-space: nowrap;
          @include text-styles(0.72vw, 600, $user-profile-name-color, center, $primary-font-family);
        }
      }

      .content_first_sect {
        width: 100%;
        display: flex;
        @include flex-box(flex-start, center, row);

        h3, p {
          line-height: 1;
          margin-bottom: 0;
        }

        h3 {
          min-width: 1%;
          max-width: 22%;
          line-height: unset;
          @include text-styles(0.64vw, 500, $user-profile-name-color, left, $primary-font-family);
          @include text-elipses();
        }

        .message_log_sect {
          width: 72%;
        }

        p {
          width: 100%;
          line-height: 1.5;
          margin-left: 0.44vw;
          @include text-styles(0.64vw, 500, $role-color, left, $primary-font-family);
          word-break: break-word;
        }

        img {
          margin-right: 0.909vw;
          @include img-styles(1.364vw, 1.364vw, cover, 50%);
        }

        div.avatar_skeleton {
          margin-right: 0.44vw;
          width: 1.364vw;
          height: 1.364vw;

          span {
            width: 1.364vw;
            height: 1.364vw;
          }
        }

        .action_date {
          display: none;
          white-space: nowrap;
          font-size: 0.65vw;
          font-weight: 500;
          color: #504979B3;
          text-align: right;
          font-family: "Roboto", sans-serif;
        }
        .action_ip {
          display: none;
        }
      }

      .content_second_sect {
        width: 23%;
        display: flex;
        @include flex-box(flex-end, center, row);

        &.content_second_sect_progress {
          width: 45%;
        }

        .action_date {
          white-space: nowrap;
          flex: 1;
          @include text-styles(0.65vw, 500, $role-color, right, $primary-font-family);
        }

        .action_ip {
          width: max-content;
          margin-left: 1.2vw;
          @include text-styles(0.65vw, 500, $role-color, left, $primary-font-family);
        }

        .action_progress {
          margin-right: 1.2vw;
          flex: 1;
          padding-left: 2.36vw;
          display: flex;
          @include flex-box(space-between, center, row);

          .progress_sect {
            flex: 3;
            padding-right: 0.6vw;
          }

          span {
            line-height: 1;
            flex: 0.8;
            display: flex;
            @include flex-box(flex-start, center, row);
            @include text-styles(0.95vw, 500, $user-profile-name-color, left, $primary-font-family);
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ////

  //// list item add/remove animation

  .item-animation-enter {
    transition-timing-function: ease-in-out;
    opacity: 0;
    transition: 0.4s linear;
  }

  .item-animation-enter-active {
    transition-timing-function: ease-in-out;
    opacity: 1;
    transition-duration: 0.4s;
  }

  .item-animation-exit {
    transition-timing-function: ease-in-out;
    opacity: 1;
    transition-duration: 0.4s;
  }

  .item-animation-exit-active {
    transition-timing-function: ease-in-out;
    opacity: 0;
    transform: translateY(-15%);
    transition-duration: 0.4s;
  }

  //// empty data warning styles

  .empty_data_warning {
    width: 100%;
    border: 0.05vw solid $completed-item-border-color;
    padding: 1vw;
    display: flex;
    border-radius: 0.2vw;
    @include flex-box(flex-start, center, row);

    h3 {
      line-height: 1;
      margin-bottom: 0;
      @include text-styles(0.85vw, 500, $status-bar-progress-color, left, $primary-font-family);

      &:first-letter {
        text-transform: uppercase;
      }

      a {
        color: unset;
        font-weight: unset;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  //// page header which don't have tabs

  header.section_header {
    margin-bottom: 1.7vw;
    padding-bottom: 1.7vw;
    padding-top: 0.55vw;
    border-bottom: 0.05vw solid $primary-border-color;
    display: flex;
    @include flex-box(flex-start, center, row);

    > button {
      position: absolute;
      right: 3.5vw;
      top: 1vw;
    }
  }

  ////  Form page header

  header.form_page_header {
    margin-bottom: 1.2vw;
    display: flex;
    @include flex-box(center, flex-start, column);

    .title_sect {
      display: flex;
      @include flex-box(flex-start, center, row);
      $text-font-size: 1.55vw;

      h2 {
        margin-bottom: 0;
        line-height: 1;

        &:nth-of-type(1) {
          cursor: pointer;
          border-bottom: 0.05vw solid $btn-secondary-color;
          @include text-styles($text-font-size, 500, #561884, left, $primary-font-family);
        }

        &:nth-of-type(2) {
          @include text-styles($text-font-size, 500, $user-profile-name-color, left, $primary-font-family);
        }
      }

      span {
        margin: 0 0.3vw;
        @include text-styles($text-font-size, 500, $role-color, left, $primary-font-family);
      }
    }
  }

  //// Selection way controller section

  .selection_way_controller {
    border-bottom: 0.05vw solid $primary-border-color;
    min-width: 29vw;
    padding-left: 2vw;
    display: flex;
    width: 100%;
    @include flex-box(flex-start, flex-end, row);

    .selection_way {
      padding: 0 0.65vw 1vw 0.65vw;
      line-height: 1;
      border: none;
      border-bottom: 0.1vw solid transparent;
      cursor: pointer;
      outline: none;
      background: transparent;
      @include text-styles(0.82vw, 500, $role-color, center, $primary-font-family);

      &:disabled {
        cursor: default;
        user-select: none;
        opacity: 0.6;
      }
    }

    .selection_way.active {
      border-bottom: 0.1vw solid $tab-primary-color;
      @include text-styles(0.82vw, 600, $tab-primary-color, center, $primary-font-family);
    }

    .selection_way_organization {
      position: relative;
      display: flex;
      @include flex-box(center, center, row);
      padding: 0 0.65vw 1.1vw 0.65vw;

      .custom_switcher.ant-switch-checked {
        .ant-switch-handle {
          margin-left: 0.6vw!important;
        }
      }

      .toggle {
        height: 0.65vw;
        min-width: 2.5vw;

        .ant-switch-handle {
          width: 1vw;
          height: 1vw;
          top: -0.2vw;
          margin-left: -0.2vw;
        }
      }
    }
    .selection_way_organization_text {
      margin: 0;
      @include text-styles(0.82vw, 500, $user-profile-name-color, center, $primary-font-family);
      line-height: 1;
    }

    .selection_way_toggle_wrapper {
      position: absolute;
      left: 100%;
    }
  }

  //// infinity list

  .infinite_list {
    padding: 0.75vw;
    list-style: none;
    margin-bottom: 0;
    background: white;
    z-index: 4;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 1vw #00000029;
    border-radius: 0.25vw;

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar {
      width: 0.2vw;
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.2vw;
      background: $btn-secondary-color;
    }

    li {
      margin-bottom: 0.3vw;
      cursor: pointer;
      width: 100%;
      @include text-elipses();

      &:last-child {
        padding: 0 !important;
        margin-bottom: 0;
      }
    }

    .loading_item {
      display: flex;
      @include flex-box(center, center, row);
    }
  }

  //// pagination styles

  .pagination_sect {
    padding: 2vw 0;
    display: flex;
    @include flex-box(center, center, row);
  }

  .custom_pagination {
    display: flex;
    @include flex-box(center, center, row);
    min-height: 2.8vw;

    li {
      transition: 0.4s;
      margin: 0;
      border: 0.05vw solid $adding-sect-border-color;
      background: $adding-sect-background;
      width: 2.5vw;
      height: 2.8vw;
      display: flex;
      border-right: none;
      border-radius: 0;
      @include flex-box(center, center, row);

      a {
        border: none !important;
        width: 100%;
        background: transparent !important;
        display: flex !important;
        @include text-styles(0.72vw, 500, $adding-sect-border-color, center, $primary-font-family);
        @include flex-box(center, center, row);
        text-decoration: underline;
      }

      &:last-child {
        border-radius: 0 0.15vw 0.15vw 0;
        border-right: 0.05vw solid $adding-sect-border-color !important;
      }

      &:first-child {
        border-radius: 0.15vw 0 0 0.15vw;
      }

      &:hover {
        border: 0.05vw solid $adding-sect-border-color;
        border-right: none;

        a {
          color: $adding-sect-border-color !important;
        }
      }

      &:focus {
        border-color: $adding-sect-border-color;
      }
    }

    li.ant-pagination-item-active {
      transition: 0.4s;
      width: 3vw;
      height: 3.2vw;
      background: white;
      border-radius: 0.15vw;
      box-shadow: 0 0 15px #7440B553;
      border-bottom: 0.15vw solid $user-profile-name-color;
      border-right: 0.05vw solid $adding-sect-border-color;

      a {
        text-decoration: none;
        @include text-styles(0.82vw, 500, $user-profile-name-color, center, $primary-font-family);
      }
    }

    li.ant-pagination-item-active + li {
      border-left: none;
    }
  }

  //// Filter styles

  .team_filter_sect {
    position: relative;

    input {
      border: none;
      outline: none;
      background: transparent;
      max-width: 9vw;
      @include text-styles(0.85vw, 500, $user-profile-name-color, left, $primary-font-family);

      &::placeholder {
        @include text-styles(0.85vw, 500, $filter-form-placeholder-color, left, $primary-font-family);
      }
    }

    .team_filter {
      max-height: 14vw;
      top: 100%;
      right: 0;
      z-index: 10;
      padding-left: 1.1vw !important;
      width: 100%;
    }
  }

  .user_role_filter_sect {
    position: relative;
    margin-right: 0.5vw;

    p {
      margin-bottom: 0;
    }

    .role_select {
      max-height: 1.3vw;
      display: flex;
      @include flex-box(center, center, row);
      width: 8vw;

      .ant-select-selection-placeholder {
        line-height: unset !important;
      }

      .ant-select-selection-item {
        border-radius: 0 !important;
        background: none !important;
        border: none !important;
        height: unset !important;
        @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);
      }
    }
  }

  .gamification_action_filter_sect {
    position: relative;
    margin-right: 0.5vw;

    p {
      margin-bottom: 0;
    }

    .ant-picker {
      .ant-picker-input {
        > input {
          font-size: 0.82vw;
        }
      }
    }

    .action_select {
      max-height: 1.3vw;
      display: flex;
      @include flex-box(center, center, row);
      width: 8vw;

      .ant-select-selection-placeholder {
        line-height: unset !important;
      }

      .ant-select-selection-item {
        border-radius: 0 !important;
        background: none !important;
        border: none !important;
        height: unset !important;
        @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);
      }
    }
  }

  .sort_dropdown {
    width: auto !important;

    .sort_option {
      font-size: 0.75vw;
      padding: 0.25vw 0.5vw;
      min-height: 1vw;
    }
  }

  .descendant {
    margin-right: 0.5vw;
    border: none;
    outline: none;
    cursor: pointer;
    @include icon-styles(1.2vw, 1.2vw, "descendant.svg", contain);
  }

  .secondary_filter_actions {
    width: max-content;
    display: flex;
    margin-left: auto;
    @include flex-box(flex-end, center, row);

    p {
      margin-bottom: 0;
      line-height: 1;
      margin-right: 0.3vw;
      @include text-styles(0.72vw, 600, $role-color, left, $primary-font-family);
    }

    .ant-select {
      margin-bottom: 0.18vw;

      &.ant-select-open {
        .ant-select-arrow {
          transform: rotateX(180deg);
        }
      }

      .ant-select-selector {
        height: 1.45vw;
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;

        .ant-select-selection-item {
          min-width: 8vw;
          line-height: 2;
          border-bottom: 0.1vw solid $btn-secondary-color;
          @include text-styles(0.75vw, 600, $btn-secondary-color, left, $primary-font-family);
        }

        .ant-select-selection-search-input {
          font-size: 18px;
        }
      }
      .ant-select-arrow {
        transition: transform .3s ease-in-out;
      }
    }
  }

  //// Notification styles

  .notif_success {
    border: 0.05vw solid $notification-success-border;
    background: $notification-success-background;
    color: $notification-success-color;

    .ant-notification-notice-message {
      color: $completed-item-border-color !important;
    }
  }

  .notif_error {
    border: 0.05vw solid $notification-error-border;
    background: $notification-error-background;
    color: $notification-error-color;

    .ant-notification-notice-message {
      color: $status-bar-problems-color !important;
    }
  }

  .notif_warning {
    border: 0.05vw solid $notification-warning-border;
    background: $notification-warning-background;
    color: $notification-error-color;

    .ant-notification-notice-message {
      color: $notification-warning-border !important;
    }
  }

  //// Scroll able elements styles

  .scroll_able_element {
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar {
      width: 0.1vw;
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.1vw;
      background: transparent linear-gradient(145deg, $on-track-indicator-color 0, $on-track-secondary-indicator 100%) 0 0 no-repeat padding-box;
    }
  }

  //// Comments section styles
  .comments_list {
    list-style: none;
    padding: 0;

    li {
      > div:first-child {
        width: 100%;
      }
    }

    .ant-spin {
      justify-content: center;
    }

    > li > div {
      white-space: nowrap;
      display: flex;
      @include flex-box(flex-start, center, row);

      p, h3 {
        margin-bottom: 0;
        line-height: 1;
      }

      .weekly_items_date {
        font-size: 0.69vw;
        margin-left: 0;
        margin-right: 0;
        color: $hierarchy-btn-color;
        opacity: 0.8;
      }

      .creator_img_wrapper {
        display: flex;
        @include flex-box(center, center, row);
        margin-right: 0.25vw;
      }

      img {
        @include img-styles(1.364vw, 1.364vw, cover, 50%);
      }

      div.avatar_skeleton {
        width: 1.1vw;
        height: 1.1vw;
        margin-right: 0.25vw;

        span {
          width: 1.1vw;
          height: 1.1vw;
        }
      }

      h3 {
        margin-right: 0.6vw;
        @include text-styles(0.69vw, 500, $user-profile-name-color, left, $primary-font-family);
      }

      p {
        padding: 5px 15px 5px 0;
        display: inline-block;
        word-break: break-word;
        white-space: break-spaces;
        width: 100%;
        @include text-styles(0.69vw, 500, $role-color, left, $primary-font-family);

        a {
          display: inline-block;
          padding: 5px 0;
          @include text-styles(0.69vw, 500, $role-color, left, $primary-font-family);
        }
      }

      .actions_menu {
        margin-left: 0.7vw;
        @include icon-styles(0.8vw, 0.8vw, "shapes-and-symbols.svg", contain);
        cursor: pointer;
      }
    }

  }

  .comment_container {
    position: relative;
    padding: 0.84vw 0.4vw 0.84vw 1vw;
    display: flex;
    background: $secondary-menu-item-color;
    border-top: 0.05vw solid $primary-border-color;
    @include flex-box(flex-start, flex-start, row);

    .comment_input_area_wrapper {
      position: static;
      width: 100%;

      .datalist-list {
        max-height: 11vw;
      }

      .comment_input_area_mobile_header {
        width: 100%;
        display: flex;
        @include flex-box(space-between, center, row);

        .comment_input_area_mobile_header_actions {
          display: flex;
          @include flex-box(space-between, center, row);

          .cancel_edit_btn {
            font-size: 3.2vw;
            margin-right: 4vw;
            margin-bottom: 0;
            padding: 0.7vw 0;
          }
        }
      }

      &.invalid {
        .comment_input_area {
          border: 0.05vw solid $status-bar-problems-color;
        }
      }
    }

    .comment_input_area {
      width: 100%;
      border-radius: 0.3vw;
      display: flex;
      align-items: flex-end;

      .comment_input_area_textarea_wrapper {
        width: 100%;
      }

      textarea {
        background-color: transparent;
        margin: 0.6vw 0 0.6vw 0.56vw;
        height: auto;
        width: 100%;
        border: 0;
        border-radius: 0.3vw;
        outline: none;
        resize: none;
      }
    }

    .primary_action {
      white-space: nowrap;
      margin: 0.35vw 0.5vw;
      padding: 0.5vw 0.6vw;
      @include text-styles(0.64vw, 600, white, left, $primary-font-family);
    }

    button.cancel_edit_btn {
      border-left: none;
      border-top: none;
      border-right: none;
      margin-left: 0;
      margin-bottom: 0.7vw;
      &.hidden {
        display: none;
      }
    }

    img {
      margin: 0.26vw 0.68vw 0 0;
      @include img-styles(2.15vw, 2.15vw, cover, 50%);
    }

    div.avatar_skeleton {
      margin-top: 0.26vw;
      width: 2.15vw;
      height: 2.15vw;

      span {
        width: 2.15vw;
        height: 2.15vw;
      }
    }
  }

  //// filter control btn

  .hideFilter {
    height: 0;
    min-height: 0;
    margin-bottom: 0;
    overflow: hidden;
  }

  .filter_btn_place {
    margin-right: 1.35vw;
    padding-right: 0.92vw;
    border-right: 0.01vw solid $primary-border-color;
    display: flex;
    @include flex-box(center, center, row);

    .burger_filter_btn {
      position: relative;
      border: none;
      outline: none;
      cursor: pointer;
      @include icon-styles(1.5vw, 1.1vw, "icon-filters.svg", contain);

      .filter_count {
        position: absolute;
        top: -40%;
        right: -10%;
        background-color: $notification-reminder-color;
        border-radius: 50%;
        min-width: 1vw;
        height: 1vw;
        display: flex;
        @include flex-box(center, center, row);
        @include text-styles(0.6vw, 600, white, center, $primary-font-family);
      }
    }
  }

  .author_filter, .template_filter {
    margin-right: 0.5vw;
    display: flex;
    @include flex-box(flex-start, center, row);

    p {
      margin-right: 0.2vw;
      margin-bottom: 0;
    }

    input {
      outline: none;
      border: none;
      background: transparent;
      @include text-styles(0.82vw, 500, $user-profile-name-color, left, $primary-font-family);

      &::placeholder {
        @include text-styles(0.82vw, 500, $role-color, left, $primary-font-family);
      }
    }
  }

  //// Date interval select

  .date_interval_container {
    position: absolute;
    top: 1.25vw;
    right: 3.5vw;
    display: flex;
    z-index: 1;
    @include flex-box(flex-start, center, row);

    span.date_title {
      margin-right: 0.65vw;
      white-space: nowrap;
      @include text-styles(0.75vw, 600, $btn-secondary-color, center, $primary-font-family);
    }

    .date_interval_skeleton {
      border-radius: 0.3vw;
      width: 10.1vw;
      height: 2.8vw;
    }

    .week_picker {
      display: flex;
      z-index: 1;
      border: 0.1vw solid $secondary-border-color;
      background: white;
      border-radius: 0.3vw;
      border-bottom-width: 0.2vw;
      @include flex-box(center, center, row);

      .left_arrow_container, .right_arrow_container {
        cursor: pointer;
        padding: 0.95vw 1.2vw;
        width: 1.5vw;
        height: 100%;
        display: flex;
        @include flex-box(center, center, row);

        .arrow {
          min-width: 0.67vw;
          min-height: 0.67vw;
          display: inline-block;
          @include icon-styles(0.67vw, 0.67vw, "down-arrow.svg", contain);
        }
      }

      .left_arrow_container {
        border-right: 0.1vw solid $secondary-border-color;

        .arrow {
          transform: rotateZ(90deg);
        }
      }

      .right_arrow_container {
        border-left: 0.1vw solid $secondary-border-color;

        .arrow {
          transform: rotateZ(-90deg);
        }
      }

      .review_period_title {
        padding: 0 0.75vw;
        @include text-styles(0.75vw, 500, $btn-secondary-color, center, $primary-font-family);
      }

      .interval_picker {
        padding: 0;
        width: 100%;
        box-shadow: none;

        .ant-select-arrow {
          top: 50%;
          right: 1vw;
          transition: transform .3s ease-in-out;

          @include desktop-media {
            right: 1.5vw;
          }
          @include small-desktop-media {
            right: 1.5vw;
          }
        }

        &.ant-select-open {
          .ant-select-arrow {
            transform: rotateX(180deg);
          }
        }

        div {
          background: transparent;
          border: none;
          outline: none;
          height: unset;
          padding: 0 1vw;
          box-shadow: none !important;

          @include small-desktop-media {
            padding: 0 2vw;
          }

          input {
            height: inherit !important;
            line-height: 1;
            @include text-styles(0.8vw, 500, $btn-secondary-color, center, $primary-font-family);
          }

          .ant-select-selection-search {
            line-height: normal !important;
          }

          .ant-select-selection-item {
            padding-right: 1vw;
            @include text-styles(0.8vw, 500, $btn-secondary-color, center, $primary-font-family);

            @include desktop-media {
              padding-right: 2vw;
            }
            @include small-desktop-media {
              padding-right: 2vw;
            }
          }
        }
      }
    }
  }

  //// modals

  .selection_modal {
    width: $modal-desktop-size;

    .modal_content {
      padding: 0 0 2vw 0 !important;

      .input_section {
        margin-bottom: 2vw;
        display: flex;
        @include flex-box(center, center, column);

        .radio_group {
          width: 100%;

          .goals_list {
            max-height: 40vh;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 0;
            width: 100%;
            display: flex;
            padding: 0;
            list-style: none;
            @include flex-box(flex-start, flex-start, column);

            .loading_item {
              width: 100%;
              display: flex;
              @include flex-box(center, center, row);
            }

            label {
              border-top: 1px solid $primary-border-color;
              width: 100%;
              padding: 0.6vw 2vw;
              display: flex;
              @include flex-box(flex-start, center, row);

              &:last-child {
                border-bottom: 1px solid $primary-border-color;
              }

              .ant-radio {
                span {
                  margin-right: 0.3vw;
                  width: 1.3vw;
                  height: 1.3vw;
                  border-radius: 50%;
                }
              }

              .ant-radio ~ span {
                width: 100%;
                display: inline-block;

                @include text-styles(0.75vw, 500, $user-profile-name-color, left, $primary-font-family);
                @include text-elipses();
              }

              .ant-radio-checked {
                span {
                  position: relative;
                  width: 1.3vw;
                  height: 1.3vw;
                  border-radius: 50%;
                  border: 0.1vw solid $switcher-active-border;
                  background: $on-track-indicator-color;

                  &:after {
                    background: transparent;
                    content: "L";
                    opacity: 1;
                    transition: 0.3s;
                    color: white;
                    position: absolute;
                    transform: rotateY(180deg) rotateZ(320deg) scale(1, 1) translate(90%, -20%);
                    left: 50%; // 35
                    top: 50%; // 12
                    line-height: 1;
                    font-weight: bold;

                    @include small-desktop-media {
                      font-size: 0.82vw;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .actions_sect {
        padding-left: 2vw;
        display: flex;
        @include flex-box(flex-start, center, row);
      }
    }
  }

  .emoji_pallet_modal {
    position: absolute;
    left: 48.8%;
    right: auto;
    z-index: 1;
    height: max-content;

    .emoji-mart-bar {
      .emoji-mart-preview {
        .emoji-mart-preview-data {
          .emoji-mart-title-label {
            font-size: 0.818vw;
          }
        }
      }
    }

    .emoji_animation_sect {
      opacity: 0;
      transition: 0.25s;
    }

    button {
      outline: none;
    }
  }

  // quick tour close button
  .react-joyride__tooltip {
    & > button {
      display: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.rate {
  display: inline-flex;

  .anticon-star {
    .ant-rate-star:not(:last-child) {
      margin-right: 0.64vw;
    }

    svg {
      width: 1.5vw;
      height: 1.5vw;
    }
  }
}

.see_full_list {
  @include text-styles(0.78vw, 500, $tab-primary-color, left, $primary-font-family);
  outline: none;
  background-color: #ffffff;
  border: solid $tab-primary-color 0.1vw;
  border-bottom-width: 0.2vw;
  border-radius: 5px;
  padding: 0.409vw 0.659vw;
  transition: background-color .2s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: $tab-primary-color-hover-bg;
  }
  &:active {
    background-color: $tab-primary-color-active-bg;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      a {
        p {
          margin-bottom: 0;
        }
        svg {
          display: none;
        }
      }
    }
  }
}

.app_content {
  flex: 1;
  max-width: 100%;
  display: flex;
  @include flex-box(space-between, flex-start, column);

  > .ant-row {
    width: 100%;
  }

  @media (min-width: 1200px) {
    max-width: calc(100% - 12.5vw) !important;

    &.sidebar_collapsed {
      max-width: calc(100% - 4.199vw) !important;

    }
  }
}

// White input field with actions
.input_area {
  border-radius: 0.3vw;
  border: 0.05vw solid $secondary-border-color;
  background: white;
  display: flex;
  @include flex-box(space-between, center, row);

  .title_input_field {
    width: 100%;
    line-height: 1;
    border: none;
    outline: none;
    padding: 0.595vw 0.5vw 0.595vw 1.05vw;
  }

  .advanced_actions {
    padding: 0.55vw 0.5vw;
    display: flex;
    @include flex-box(flex-end, center, row);

    .action_sections {
      margin-right: 1vw;
      display: flex;
      @include flex-box(center, center, row);
    }

    .action_modal_btn {
      padding: 0;
      cursor: pointer;
      border: none;
      outline: none;
    }

    .assignees_sect {
      @extend .action_sections;

      .assign_to {
        @include icon-styles(1.02vw, 1.18vw, "icon-user.svg", auto);
        @extend .action_modal_btn;
      }

      .selected_user_avatar {
        margin-left: 0.35vw;
        @include img-styles(1.22vw, 1.22vw, cover, 50%)
      }

      div.avatar_skeleton {
        margin-left: 0.35vw;
        width: 1.22vw;

        span {
          width: 1.22vw;
          height: 1.22vw;
        }
      }
    }

    .selected_date_sect {
      @extend .action_sections;

      .date_btn {
        @include icon-styles(1.1vw, 1.18vw, "icon-timer-silver.svg", auto);
        @extend .action_modal_btn;
      }

      .data_text {
        white-space: nowrap;
        margin-left: 0.25vw;
        @include text-styles(0.68vw, 500, $user-profile-name-color, left, $primary-font-family);
      }
    }

    .attached_files {
      position: relative;
      @extend .action_sections;

      .fastener {
        @include icon-styles(0.98vw, 1.18vw, "icon-attachment-grey.svg", auto);
        @extend .action_modal_btn;
      }

      .attached_files_count {
        line-height: 1;
        position: absolute;
        top: -20%;
        right: -40%;
        background: $count-indicator-background;
        width: 0.85vw;
        height: 0.85vw;
        border-radius: 50%;
        display: flex;
        @include flex-box(center, center, row);
        @include text-styles(0.45vw, 600, white, left, $primary-font-family);
      }
    }

    button.cancel_btn{
      border-left: none;
      border-top: none;
      border-right: none;
      margin-left: 0.65vw;
    }

    .remove_selected_items {
      cursor: pointer;
      padding: 0;
      border: none;
      outline: none;
      margin-right: 1vw;
      @include icon-styles(0.75vw, 0.75vw, "close.svg", contain);
    }

    .primary_action {
      padding: 0.45vw 1.1vw;
      white-space: nowrap;
      font-size: 0.64vw;
      transition: all 0.2s ease-out;

      &:disabled {
        opacity: 0.35;
      }
    }
  }
}

.leaderboard_star {
  width: 2.3vw;
  height: 2.3vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.gamification_top_logo {
  position: relative;
}

.gamification_top_container {
  height: 100%;
  border-left: 1px solid #EAE4F4;
  margin-left: 0.5vw;
}

.gamification_top {
  height: 100%;
  display: flex;
  @include flex-box(center, center, row);

  .gamification_total_count {
    bottom: 0.75vw;
  }
}

.gamification_logo_with_total_count {
  margin-left: 0.5vw;

  img {
    width: 3.15vw;
    height: auto;
  }
}

.gamification_total_count {
  position: absolute;
  z-index: 1;
  left: 0.9vw;
  bottom: -3.7px;
  width: 70%;
  height: auto;
  background-color: white;
  color: #A55C13;
  font-size: 0.55vw;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #A55C13;
}

@include small-desktop-media {
  body {
    .filter_form {
      flex-wrap: unset;
      row-gap: unset;
      .filter_item {
        max-width: 21.5vw;
        height: 3.4vw;
        padding: 0 0.95vw;
      }
    }
  }
}

@include tablet-media {
  body {
    .chart_tooltip {
      padding: 1vw 1vw;
      span {
        @include text-styles(1.8vw, 600, $user-profile-name-color, left, $primary-font-family);
      }
    }

    .chart_tooltip_first_point {
      transform: unset;
      -webkit-transform: unset;
    }

    .goal_card_actions_menu {
      width: 2.995vw;
      height: 2.995vw;
      margin-left: 2.5vw;
    }

    .custom_intercom {
      height: 9.635vw;
      font-size: 1.823vw;

      svg {
        margin-right: 1.818vw;
      }
    }

    .intercom-namespace {
      .intercom-1p31mt3 {
        transform-origin: left bottom!important;
      }

      .intercom-1oe5ewx {
        transform-origin: left bottom!important;
      }
    }

    .enps_rate_numbers {
      font-size: 1.4vw!important;
    }

    .enps_sect {
      .enps_likely_explanation {
        margin: 0 1.1vw 1vw 0;
      }
    }

    .enps_likely_explanation {
      margin: 0.7vw 0 0.7vw 0;
      width: 100%;

      span {
        font-size: 1.55vw;
      }
    }

    .enps_satisfaction_rate_container {
      margin-right: 1.5vw;
      margin-left: 0.5vw;
      flex-direction: column!important;
    }

    .enps_overall_text {
      margin-left: auto!important;
    }

    .apm_modal.filter_modal {
      max-width: 78.125vw;
      width: 100%;

      .modal_content {
        width: 100%;
        padding-top: 0;

        .filter_form {
          width: 100%;
          @include flex-box(flex-start, flex-start, column);
          margin-bottom: 0;
        }

        .clear_btn {
          font-size: 1.8vw;
          margin-left: 2vw;
        }
      }
    }

    .nested_tabs {
      .ant-tabs-tab {
        font-size: 1.8vw!important;
      }
    }

    .filter_form {
      margin-bottom: 3.8vw;
      flex-wrap: unset;
      row-gap: unset;

      .main_filter_actions {
        width: 100%;
        @include flex-box(flex-start, center, row);
        flex-wrap: wrap;
        height: unset !important;

        .deadline_select .ant-picker .ant-picker-input {
          .ant-picker-clear {
            background-color: transparent;
            font-size: 2.583vw;
            right: 0;
          }

          .ant-picker-suffix {
            line-height: 0;
            margin-left: 1vw;
            svg {
              width: 2.8vw;
              height: 3.15vw;
            }
          }

          input {
            width: 100%;
            font-size: 2.083vw;
            max-width: 14.5vw;

            &::placeholder {
              font-size: 2.083vw;
            }
          }
        }
      }

      input {
        max-width: unset;
        width: 100%;
        font-size: 2.083vw;

        &::placeholder {
          font-size: 2.083vw;
        }
      }

      .filter_item {
        height: unset;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 2.604vw;
        padding: 2.344vw 2.604vw 2.734vw;
        max-width: unset;
        p {
          font-size: 2.083vw;
          margin-right: 1.5vw;
        }

        & > div {
          width: unset;
          flex: 1;
        }

        > div input {
          max-width: unset;
          width: 100%;
          font-size: 2.083vw;

          &::placeholder {
            font-size: 2.083vw;
          }
        }

        .ant-select {
          .ant-select-clear {
            font-size: 2.344vw;

            span {
              background-color: $grey-background-color;
            }
          }

          .ant-select-selector {
            .ant-select-selection-placeholder {
              font-size: 2.083vw;
            }
          }
        }

        &.user_select {
          padding: 2vw 2.604vw 2.38vw;
          min-height: 8.908vw;

          .ant-select .ant-select-selector .ant-select-selection-search {
            .ant-select-selection-search-input {
              font-size: 2.083vw;
            }
          }

          .ant-select .ant-select-selector .ant-select-selection-item {
            padding: 0.573vw 1.432vw;
            height: fit-content;
            max-height: 7.2vw;
            border-radius: 2.604vw;

            .ant-select-selection-item-content {
              font-size: 1.949vw;
              margin-right: 0.7vw;
            }
            .ant-select-selection-item-remove {
              font-size: 1.3vw;
            }
          }
        }

        &.user_role_filter_sect {
          align-items: center;
          justify-content: flex-start;

          .ant-select {
            min-height: unset;
            max-height: 3.404vw;

            &.role_select {
              .ant-select-selector .ant-select-selection-item {
                padding: 0;
                max-height: 3.404vw;
                line-height: 1.8;
              }
            }
          }
        }

        &.gamification_action_filter_sect {
          align-items: center;
          justify-content: flex-start;

          .ant-select {
            min-height: unset;
            max-height: 3.404vw;

            &.action_select {
              .ant-select-selector .ant-select-selection-item {
                padding: 0;
                max-height: 3.404vw;
                line-height: 1.8;
              }
            }
          }
        }
      }

      .action_btn {
        padding: 2.669vw 3.646vw;
        height: unset;
        font-size: 1.823vw;
      }

      .clear_btn {
        margin-left: 2vw;
        font-size: 2.004vw;
      }

      .close_filter_modal {
        font-size: 1.693vw;
        margin-left: 2.93vw;
      }
    }

    .selection_way_controller {
      padding-left: 3.25vw;

      .selection_way {
        padding: 0 1.3vw 1vw 1.3vw;
        font-size: 2.344vw;
        margin-right: 1.65vw;
      }

      .selection_way.active {
        font-size: 2.344vw;
      }

      .selection_way_organization {
        padding: 0 1.3vw 1.15vw 1.3vw;

        .toggle {
          height: 1.148vw;
          width: 6.594vw;

          .ant-switch-handle {
            width: 2.906vw;
            height: 2.906vw;
            top: -0.93vw;
          }
        }
      }
      .selection_way_organization_text {
        font-size: 2.344vw;
      }
    }

    .multiple_select_checkbox {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 16px;
          height: 16px;
          border-color: #d9d9d9 !important;
          background-color: #fafafa;

          &::after {
            top: 50%;
            width: 5.71428571px;
            height: 9.14285714px;
          }
        }

        &-checked {
          .ant-checkbox-inner {
            background-color: #9791BA;
          }
        }
      }
    }

    .default_outlined_btn {
      padding: 0 1.833vw;
      font-size: 1.833vw;
      border-width: 0.142vw;
      border-bottom-width: 0.285vw;
      border-radius: 0.716vw;
      min-height: 4.841vw;
    }

    h1.page_title {
      font-size: 2.474vw;
    }

    .progress_tooltip_custom .ant-tooltip-inner {
      padding: 1.042vw 2.344vw;
      font-size: 2.635vw;
    }

    .selection_modal {
      width: $modal-tablet-size;
      .modal_content {
        .modal_title {
          font-size: 1.4vw !important;
        }
        .ant-radio-checked {
          span::after {
            font-size: 9px;
            left: 44%;
            top: 57%;
          }
        }
      }
      .modal_close_btn {
        width: 2vw !important;
        height: 2vw !important;
      }
    }

    .weekly_item_created_date {
      font-size: 1.4vw;
      bottom: 0.5vw;
    }

    .custom_pagination {
      min-height: 6.199vw;

      li {
        width: 4.844vw;
        height: 4.844vw;

        &.ant-pagination-item-active {
          width: 5.52vw;
          height: 6.2vw;

          a {
            font-size: 1.81vw;
          }
        }

        a {
          font-size: 1.6vw;
        }
      }
    }

    .toggle {
      height: 1.148vw;
      width: 6.594vw;

      .ant-switch-handle {
        width: 2.906vw;
        height: 2.906vw;
        top: -0.93vw;
      }
    }

    .total_count {
      font-size: 1.6vw;
      padding: 0.8vw 1.25vw;
      margin-right: 1.5vw;
    }

    .sort_dropdown {
      .sort_option {
        font-size: 0.85vw;
      }
    }
    .form_item {
      width: 100%;
      .ant-picker .ant-picker-input input {
        font-size: 2.083vw;
        &::placeholder {
          font-size: 2.083vw;
        }
      }
      .goal_input, .ant-picker {
        font-size: 1.62vw;
        &::placeholder {
          font-size: 1.36vw;
        }
      }
    }
    .add_goal_form button.advanced_settings {
      font-size: 1.3vw;
    }
    button.cancel_btn {
      font-size: 1.823vw;
    }
    .cancel_edit_btn_weekly_item {
      font-size: 1.833vw!important;
      align-self: flex-end;
      margin-bottom: 2.6vw!important;
    }
    .cancel_edit_btn_kr {
      font-size: 1.833vw!important;
      margin-bottom: 2.3vw!important;
      padding-top: 1.5vw!important;
      padding-bottom: 0.7vw!important;
      align-self: flex-end;
    }
    .main_actions .action_item p {
      font-size: 1.56vw;
    }
    .sort_dropdown {
      .sort_option {
        padding: 1vw 2vw;
        font-size: 1.85vw;
      }
    }
    .tooltip_custom{
      .ant-tooltip-inner {
        font-size: 2vw;
        padding: 1.1vw 1.6vw;
      }
    }
    .tooltip_info {
      padding: 0.3px 0.84vw;
      font-size: 2.007vw;
      margin-left: 0.781vw;
    }
    .info {
      padding: 0.3px 0.84vw;
      font-size: 2.007vw;
      margin-left: 0.781vw;
    }
    .specific_user_main_sect, .accomplishments_mine {
      .adding_sect .actions_sect .advanced_actions .deadline_title {
        font-size: 1.4vw;
      }
    }
    .comments_list > li > div {
      &.comment_sender {
        width: 100%;
      }

      .creator_img_wrapper {
        margin-right: 0.716vw;
      }

      img {
        width: 3.149vw;
        height: 3.149vw;
      }

      div.avatar_skeleton {
        width: 3.149vw;
        height: 3.149vw;
        margin-right: 0.716vw;

        span {
          width: 3.149vw;
          height: 3.149vw;
        }
      }

      h3 {
        margin-right: 1.719vw;
        font-size: 1.977vw;
      }

      .weekly_items_date {
        font-size: 1.977vw;
      }

      p {
        font-size: 1.977vw;

        a {
          font-size: 1.977vw;
        }
      }

      .actions_menu {
        margin-left: 2.005vw;
        width: 2.291vw !important;
        height: 2.291vw !important;
      }
    }
    .comment_container {
      align-items: flex-start;
      padding: 2.406vw 1.146vw;
      border-width: 0.142vw;

      .comment_input_area {
        align-items: flex-start;

        .datalist-list {
          max-height: 30vw;
        }

        textarea {
          margin: 0;
          border-radius: 0.859vw;
          font-size: 1.823vw;
          max-height: 10vw;

          &::placeholder {
            font-size: 1.823vw;
          }
        }
      }

      .primary_action {
        margin: 1.22vw 1.432vw;
        padding: 1.432vw 1.719vw;
        font-size: 1.833vw;
        border-radius: 0.859vw;
      }

      img {
        margin-top: 0;
        width: 6.158vw;
        height: 6.158vw;
      }

      div.avatar_skeleton {
        margin-top: 0;
        width: 6.158vw;
        height: 6.158vw;

        span {
          width: 6.158vw;
          height: 6.158vw;
        }
      }
    }
    p.single_sect_secondary_text {
      font-size: 2.148vw;
    }
    .advanced_actions_menu {
      li {
        padding-left: 1.7vw;
        padding-right: 1.7vw;
        span {
          font-size: 1.2vw;
        }
      }

      &:before {
        left: calc(55% - 0.8vw);
        top: -0.5vw;
        border-left: 1.3vw solid transparent;
        border-right: 1.3vw solid transparent;
        border-bottom: 1.3vw solid #605E9F;
      }
    }
    .date_interval_container {
      span.date_title {
        font-size: 1.35vw;
      }
    }
    .empty_data_warning {
      border-radius: 0.43vw;

      h3 {
        line-height: 1.5;
        font-size: 2.1vw;
      }
    }
    .input_field_validation {
      font-size: 2vw;
    }
    // Weekly status
    .weekly_status {
      .interactive_list_item {
        .interactive_sub_list {
          .comments_list li {
            padding: 2.378vw 3.151vw;
            span {
              font-size: 1.977vw;
            }
          }
        }
      }
      .page_control_panel {
        h1 {
          top: 0.4vw;

          &.hidden {
            display: none;
          }
        }
      }
    }
    .page_control_panel {
      h1 {
        font-size: 2.474vw;
      }
      .weekly_status_tabs {
        &.hidden {
          .ant-tabs-nav {
            display: none;
          }
        }

        .ant-tabs-nav {
          padding-left: 21.531vw !important;
          margin-bottom: 4.622vw;

          .ant-tabs-tab {
            font-size: 2.083vw;
            padding-bottom: 3.320vw;

            &-active::after {
              height: 0.26vw;
            }
          }
        }
      }
    }

    .date_interval_container {
      top: 2.214vw;

      .date_interval_skeleton {
        border-radius: 0.859vw;
        width: 32.555vw;
        height: 5.648vw;
      }

      .week_picker {
        border: 0.285vw solid #D2CAE0;
        border-radius: 0.859vw;
        .review_period_title {
          font-size: 1vw;
        }
        .interval_picker {
          display: flex;
          @include flex-box(center, center, row);
          padding: 0;

          .ant-select-arrow {
            display: none;
          }

          > div {
            width: 100%;
            padding: 0.95vw 3.02vw;

            input {
              font-size: 1.823vw;
            }

            .ant-select-selection-item {
              width: 100%;
              padding-right: 0;
              font-size: 1.823vw;
              border-width: 0.285vw;
            }
          }
        }
        .left_arrow_container, .right_arrow_container {
          padding: 2vw 2.523vw;
          .arrow {
            min-width: 1.473vw;
            min-height: 1.473vw;
            width: 1.473vw;
            height: 1.473vw;
          }
        }
      }
    }

    p.secondary_title {
      // TODO if there is need change locally
      font-size: 2.604vw;
    }

    .goals_charts_section, .pulse_point_charts_section, .statistics_chart {
      background: none;
      flex-wrap: wrap;
      width: unset;
      flex: unset;
      align-items: stretch;
      border: none;
      box-shadow: none;

      .chart_tile {
        width: 50%;
        flex: unset;
        border: 0.05vw solid #EAE4F4;
        background-color: white;

        &:first-of-type {
          border-top-left-radius: 0.651vw;
        }
        &:nth-of-type(2) {
          border-top-right-radius: 0.651vw;
        }
        &:last-of-type {
          border: 0.05vw solid #EAE4F4;
          border-radius: 0 0 0.651vw 0.651vw;
          border-top: none;
        }
        &:nth-of-type(-n+2) {
          border-bottom: none;
        }
        &:nth-of-type(-2n+3) {
          border-right: none;
        }

        &.problems_tile {
          .chart_info_sect {
            .ws_icon {
              width: 1.953vw !important;
              height: 1.953vw !important;
              left: 0;
            }
          }
        }

        .chart_info_sect {
          p {
            font-size: 2.344vw;
            display: flex;
            @include flex-box(flex-start, center, row);

            .ws_icon {
              position: static;
              width: 1.953vw;
              height: 1.953vw;
              margin-right: 1.042vw;
            }
          }
          span {
            font-size: 1.823vw;
          }
          .percentage_value {
            font-size: 2.865vw;
          }
        }
      }
    }


    .timeline_list {
      li {
        padding: 3.667vw;
        margin-bottom: 1.059vw;

        .content_first_sect {
          flex-wrap: wrap;

          img {
            width: 3.906vw;
            height: 3.906vw;
            margin-right: 1.26vw;
          }
          h3 {
            max-width: 66%;
            width: unset;
            font-size: 1.833vw;
            margin-right: 1.260vw;
          }
          .action_date {
            display: block;
            font-size: 1.862vw;
          }
          .action_ip {
            display: block;
            margin-left: 1.5vw;
            @include text-styles(1.862vw, 500, $role-color, left, $primary-font-family);
          }
          .message_log_sect {
            width: 100%;
            margin-top: 2vw;
            p {
              padding: 0;
              margin: 0;
              max-width: unset;
            }
            .plan_icon {
              width: 1.302vw;
              height: 1.302vw;
            }
            .file_icon {
              background-position: center center;
              min-width: 2.344vw;
              width: 2.344vw;
              height: 2.344vw;
            }
          }
          p {
            font-size: 1.953vw;
            padding-left: 3.646vw;
            width: 100%;
            max-width: 39vw;
          }
        }
        .content_second_sect {
          display: none;
          .action_date {
            display: none;
          }
        }
      }
    }
    .user_credentials_sect .user_info {
      h2 {
        font-size: 2.25vw;
        margin-left: 1.432vw;
      }
      img {
        width: 3.666vw;
        height: 3.666vw;
      }
      div.avatar_skeleton, div.avatar_skeleton span {
        width: 3.666vw;
        height: 3.666vw;
      }
    }
    .teams_and_people p.go_back_btn {
      margin-bottom: 0;
      border-bottom: none;
      margin-right: 1.042vw;
      font-size: 1.765vw;
      padding-bottom: 1vw;
      text-decoration: underline;
      @include text-styles(0.82vw, 500, $btn-secondary-color, left, $primary-font-family);
      .back_btn_value {
        border-bottom: 0.05vw solid #504979B3;
      }
      .left_arrow {
        display: none;
      }
    }
    .specific_user_main_sect, .accomplishments_mine {
      .section_heading {
        padding: 5.078vw 3.776vw;
        font-size: 2.604vw;
      }
      .user_credentials_sect {
        @include flex-box(flex-end, baseline, column);
        margin-top: 0;
        margin-bottom: 4.557vw;

        & > div:first-child {
          position: relative;
          display: inline-flex;
          padding-left: 1.5vw;
          @include flex-box(flex-start, flex-start, column);

          p.go_back_btn {
            margin-bottom: 0;
            border-bottom: none;
            margin-right: 1.042vw;
            font-size: 2.865vw;

            .back_btn_value {
              border-bottom: 0.05vw solid #504979B3;
              color: #561884;
            }

            .left_arrow {
              display: none;
            }
          }
          .specific_user {
            h2 {
              font-size: 2.865vw;
              max-width: 26vw;
            }
            img {
              width: 3.125vw;
              height: 3.125vw;
            }
          }
        }

        .progress_circle {
          display: none;
        }
        .progress_bar {
          width: 100%;
          background-color: #EAE4F4;
          border-radius: 0.651vw;
          display: flex;
          margin-top: 3.818vw;
          .progress_bar_inner {
            background-color: $tab-primary-color;
            border-radius: 0.651vw;
            height: 0.651vw;
          }
        }

        & > div:nth-of-type(2) {
          margin-top: 2vw;
          span {
            font-size: 2.344vw;
          }
        }
      }
      .week_satisfaction_sect {
        padding: 3.971vw !important;

        h3 {
          font-size: 2.734vw;
        }

        div.mine_satisfaction_heading {
          margin-bottom: 2.5vw;

          p {
            font-size: 2.148vw;
          }
        }

        div.satisfaction_rate_main {
          .satisfaction_rate_sect {
            display: flex;
            margin-right: 2.005vw;

            svg {
              width: 4.209vw;
              height: 4.209vw;
            }
          }
          .checked_stars_count {
            line-height: initial;
            font-size: 2.604vw;
          }
        }
      }
      .ws_plans, .ws_progress, .ws_challenges, .ws_coming_up {
        .title_icon {
          width: 1.953vw;
          height: 1.953vw;
        }
      }

      .actions_sect {
        .advanced_actions {
          .emoji_selection {
            width: 2.995vw;
            height: 2.995vw;

          }
          .goal {
            .goal_icon {
              width: 2.995vw;
              height: 2.735vw;
            }
          }
          .fastener {
            height: 2.735vw;
            width: 1.84vw;

            .count {
              font-size: 1.302vw;
              right: -60%;
              top: -39%;
              padding: 0.516vw 0.831vw;
            }
          }
          .deadline {
            width: 2.8vw;
            height: 2.8vw;
          }
        }
        button.add_accomplishment {
          line-height: 2.344vw;
          font-size: 1.823vw;
          border-radius: 0.859vw;
        }
      }

      .adding_main_sect {
        .adding_sect {
          input.add-field {
            font-size: 2.344vw !important;
            &::placeholder {
              font-size: 2.344vw !important;
            }
          }
        }
      }

      .invalid {
        .error_sect {
          font-size: 1.977vw;
          padding-top: 0.716vw;
        }
      }
    }

    .ws_custom_status_bar {
      height: 2.604vw;

      div {
        font-size: 1.563vw;
        border-radius: 0.260vw;
      }
    }
    /////////////

    // Goals
    .goals_status_info_main {
      .goals_status_list {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        li {
          margin-bottom: 1.3vw;
          margin-right: 4vw;

          .goal_count {
            font-size: 3.125vw;
            margin-left: 1.302vw;

            span.indicator {
              width: 0.781vw;
              height: 0.781vw;
            }
          }
          p {
            font-size: 1.823vw;
            margin-left: 0.391vw;
          }
        }
      }
    }
    /////////////

    .primary_action {
      border-radius: 0.651vw;
    }

    // Modal
    #modal-root {
      .modal_container {
        .search_box {
          .secondary_search {
            padding: 0 6.8vw 0 8vw;
            height: 6.896vw;
            font-size: 2.083vw;
            &::placeholder {
              font-size: 2.083vw;
            }
          }

          .search_icon {
            font-size: 3vw;
            left: 5%;
            top: 29%;
          }

          .clear_search {
            @include icon-styles(2.635vw, 2.635vw, "close.svg", contain);
          }
        }

        .apm_modal {
          width: $modal-tablet-size;
          min-width: $modal-tablet-size;
          max-width: $modal-tablet-size;
          @include flex-box(center, flex-start, column);

          .modal_content {
            padding: 0 3.255vw 5.05vw;
            padding-bottom: 5vw !important;

            .template_filter {
              input {
                font-size: 2.083vw;

                &::placeholder {
                  @include text-styles(2.083vw, 500, $role-color, left, $primary-font-family);
                }
              }
            }
          }
        }
      }
    }
    //////////


    // Reviews
    .review_sect, .secondary_reviews_info_sect {
      span {
        &.admin_short_badge, &.manager_short_badge {
          font-size: 1.4vw;
        }
      }

      .rating-field-answer {
        margin-top: 1vw;
        margin-right: 1vw;

        .percent {
          width: 100% !important;
          margin-top: 2vw;
          font-size: 2vw;
        }
      }

      .rating-range-content {
        &__choice-value {
          max-width: 50vw;
          font-size: 1.6vw;
        }

        &__choice-checkbox {
          width: 3vw !important;
          height: 3vw;
        }
      }
      .user_credentials_sect {
        .user_info {
          .user_info_go_back {
            margin-bottom: 0;
            font-size: 2.865vw;

            .user_info_go_back_text {
              color: $btn-secondary-color;
              text-decoration: underline;
              cursor: pointer;
              font-weight: lighter;
            }
          }
          h2 {
            font-size: 2.865vw;
          }
          img {
            order: -1;
            width: 5.469vw;
            height: 5.469vw;
            margin-right: 1.042vw;
          }
          div.avatar_skeleton, div.avatar_skeleton span {
            width: 5.469vw;
            height: 5.469vw;
          }
        }
      }

      div.secondary_single_list {
        display: flex;
        flex-direction: column;
      }
      div.secondary_single_sect {
        margin-bottom: 2.604vw;
      }
      h3.secondary_single_sect_primary_text {
        font-size: 2.604vw;
        padding: 4.948vw 3.906vw 0;
      }
      .reviews_list {
        margin-top: 4.362vw;

        li.list_item {
          padding: 2.93vw 3.906vw;
          margin: 0;
          max-width: unset;
          flex-wrap: wrap;

          img {
            width: 3.385vw;
            height: 3.385vw;
            margin-right: 1.302vw;
          }
          p {
            width: 100%;
            font-size: 2.083vw;
            margin-right: 0;
            margin-top: 1vw;
          }
          .date_of_review {
            font-size: 1.6vw;
            margin-right: 0;
          }
          .rate-main-item-content {
            padding: 2.93vw 3.906vw;
            flex-wrap: wrap;
            justify-content: flex-start;

            .satisfaction_rate_sect {
              display: flex;

              svg {
                width: 2.2vw;
                height: 2.2vw;
              }
            }

            .explanation_icon {
              width: 3.255vw;
              height: 2.865vw;
            }

            .levelProgress_sect {
              width: 55vw;
              margin-right: 1.5vw;
            }
          }

          .interactive_sub_list {
            li {
              font-size: 2.083vw;
            }
          }
        }
        span.level_indicator, span.grade_indicator {
          font-size: 2.083vw;

        }

      }

      .overall_sect {
        padding: 3.516vw 3.906vw;
        &.range_answers {
          @include flex-box(flex-start, center, column-reverse);

          p {
            margin: 0 2.865vw 0 0;
          }
        }
        &.grade_answers {

          .satisfaction_rate_sect {
            display: flex;
            width: 100%;
            margin: 0;
            order: 0;

            svg {
              width: 2.279vw;
              height: 2.279vw;
            }
          }

          .grade_indicator {
            order: 1;
            width: fit-content;
            margin: 1.302vw 1.302vw 0 0;
          }

          p {
            width: fit-content;
            margin-top: 1.302vw;
            order: 2;
          }
        }

        span.grade_indicator {
          font-size: 2.344vw;
          margin-left: 1.538vw;
          margin-right: 0.911vw;
        }

        p {
          font-size: 1.823vw;
          margin: 0;
        }
      }

      .review_form {
        margin-top: 9vw;

        div.single_sect {
          padding: 3.646vw 3.906vw 5.208vw;
          .multi_choice_selection {
            label {
              margin-bottom: 2.865vw;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .ant-checkbox-inner {
              width: 3.337vw;
              height: 3.337vw;
            }
          }
          .single_choice_selection {
            label {
              margin-bottom: 2.865vw;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .choice_item {
              span.ant-radio-inner {
                width: 3.723vw;
                height: 3.723vw;
              }
            }
          }
          .rate_with_explanation {
            .satisfaction_rate_sect {
              display: flex;

              li {
                svg {
                  width: 2.604vw;
                  height: 2.604vw;
                }
              }
            }
            .hidden_input_field {
              &.show, &.hidden {
                padding: 2.204vw;
                height: unset;

                input {
                  font-size: 1.823vw;
                  height: unset;

                  &::placeholder {
                    font-size: 1.823vw;
                  }
                }
              }
            }
          }
          .input_field {
            padding: 2.204vw;
            height: unset;

            input, textarea {
              font-size: 1.823vw;
              height: unset;

              &::placeholder {
                font-size: 1.823vw;
              }
            }
          }
        }
        h3.single_sect_primary_text {
          font-size: 2.604vw;
        }
        .review_submit_btn {
          padding: 2.097vw 3.216vw;
          font-size: 2.234vw;
        }
      }
    }

    .submit_btn {
      padding: 2.097vw 3.216vw;
      font-size: 2.234vw;
      border-radius: 0.859vw;
    }

    //// page header which don't have tabs
    header.section_header {
      padding-bottom: 3.32vw;
    }

    //// Already reviewed reviews page

    .check_icon {
      font-size: 1.432vw;
      width: 2.148vw;
      height: 2.148vw;
      bottom: -0.4vw;
      right: -0.7vw;
    }
    .reviewed_users_info {
      .reviewed_users_list {
        li {
          width: 3.646vw;
          height: 3.646vw;

          img {
            width: 3.646vw;
            height: 3.646vw;
          }
        }
      }
      .details_btn {
        font-size: 1.654vw;
      }
    }

    .reviews {
      &.review {
        div {
          .single_sect {
            margin-bottom: 2.5vw;
            &_primary_text {
              font-size: 3.255vw;
              margin-bottom: 1.1vw;
            }

            .description {
              font-size: 2.083vw;
              margin-bottom: 0.5vw;
            }

            .grade_sect {
              h2 {
                font-size: 2.734vw;
              }

              ul {
                li {
                  margin-right: 0.962vw !important;
                }
                svg {
                  width: 3vw;
                  height: 3vw;
                }
              }
            }

            .single_choice_selection {
              label {
                .ant-radio {
                  .ant-radio-inner {
                    width: 3.646vw;
                    height: 3.646vw;
                    &:after {
                      left: 32.5%;
                      top: 16%;
                      font-size: 2.2vw;
                    }
                  }

                  & + span {
                    font-size: 2.083vw;
                  }
                }
              }
            }

            .multi_choice_selection {
              label {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    width: 3.255vw;
                    height: 3.255vw;
                    &:after {
                      top: 42%;
                      width: 1vw;
                      height: 2vw;
                    }
                  }

                  & + span {
                    font-size: 2.083vw;
                  }
                }
              }
            }

            h4 {
              font-size: 2.083vw;
            }
          }
        }
      }
    }

      //////////

    .form_item, .form_parent {
      margin-bottom: 2vw;

      .field_input {
        padding: 2.6vw 3vw;
        font-size: 1.823vw;

        &::placeholder {
          font-size: 1.823vw;
        }
      }

      &.invalid {
        .error_sect {
          height: unset;
          font-size: 1.977vw;
          padding-top: 0.716vw;
        }
      }
      label {
        font-size: 2vw;
        margin-bottom: 1vw;

        sup {
          font-size: 2vw;
        }
      }
    }

    .user_role_filter_sect {
      .role_select {
        .ant-select-selection-item {
          font-size: 2.15vw;
        }
      }
    }

    .gamification_action_filter_sect {
      .ant-picker {
        .ant-picker-input {
          > input {
            font-size: 2.083vw;
          }
        }
      }
      .action_select {
        .ant-select-selection-item {
          font-size: 2.15vw;
        }
      }
    }
  }

  .emoji_pallet_modal {
    .emoji-mart .emoji-mart-bar {
      .emoji-mart-preview {
        .emoji-mart-preview-data {
          .emoji-mart-title-label {
            font-size: 2.344vw;
          }
        }
      }
    }

    .emoji-mart-search input {
      &::placeholder {
        font-size: 2.083vw;
      }
    }
  }

  .app_content {
    flex: 1;
    padding-left: 7.678vw;
  }

  .leaderboard_star {
    width: 4vw;
    height: 4vw;
  }

  .gamification_logo_with_total_count {
    margin-left: 1vw;

    img {
      width: 5vw;
    }
  }

  .gamification_top {
    .gamification_total_count {
      bottom: 1.35vw;
    }
  }

  .gamification_total_count {
    left: 1.6vw;
    bottom: -7px;
    font-size: 1.302vw;
    border-radius: 2px;
  }
}

@include mobile-media {
  body {
    .intercom-namespace {
      .intercom-k2ddop {
        transform-origin: left bottom!important;
      }

      .intercom-1h1b8or {
        transform-origin: left bottom!important;
      }
    }

    .chart_tooltip {
      padding: 2vw 2vw;
      span {
        @include text-styles(3.267vw, 600, $user-profile-name-color, left, $primary-font-family);
      }
    }

    .chart_tooltip_first_point {
      transform: translateX(14.2vw);
      -webkit-transform: translateX(14.2vw);
    }

    .chart_tooltip_last_point {
      transform: translateX(-14.2vw);
      -webkit-transform: translateX(-14.2vw);
    }

    .goal_card_actions_menu {
      width: 4vw;
      height: 4vw;
      margin-left: 0;
    }

    .enps_satisfaction_rate_container {
      width: max-content!important;
    }

    .enps_sect {
      justify-content: space-between!important;

      .enps_likely_explanation {
        margin: 0 1.1vw 0.7vw 0;
      }

      .enps_result {
        margin-bottom: 3vw;
      }

      .nested_tabs {
        margin: 0!important;
      }
    }

    .enps_result {
      margin-bottom: 3vw;
    }

    .enps_item {
      justify-content: space-between!important;
      flex-wrap: nowrap!important;
    }

    .enps_likely_explanation {
      margin: 2vw 1.1vw 0.7vw 0;

      span {
        font-size: 3.2vw;
      }
    }

    .enps_rate_numbers {
      font-size: 3.2vw!important;
    }

    .satisfaction_rate_sect_ENPS {
      li {
        margin-right: 1.25vw!important;
      }
    }

    .custom_intercom {
      height: 19.733vw;
      padding: 0 5.363vw;
      font-size: 4.267vw;

      svg {
        margin-right: 4.8vw;
      }
    }

    .selection_way_controller {
      padding-left: 3.2vw;
      .selection_way {
        padding: 0 1.5vw 3vw 1.5vw;
        font-size: 4.200vw;
        margin-right: 2.65vw;
      }

      .selection_way.active {
        font-size: 4.200vw;
      }

      .selection_way_organization {
        padding: 0 2.5vw 3vw 1.5vw;

        .toggle {
          width: 13vw;
          height: 3vw;

          .ant-switch-handle {
            width: 5.5vw;
            height: 5.5vw;
            top: -1.4vw;
          }
        }
      }

      .selection_way_organization_text {
        font-size: 4.200vw;
        margin-bottom: 0.4vw;
      }
    }

    .selection_modal {
      width: $modal-mobile-size;
      .modal_content {
        .modal_title {
          font-size: 1.8vw !important;
        }
        .input_section .radio_group .goals_list label {
          .ant-radio ~ span {
            font-size: 2vw;
          }
          .ant-radio span {
            width: 12px;
            height: 12px;
          }
          .ant-radio-checked {
            span {
              width: 12px;
              height: 12px;
              &:after {
                font-size: 8px;
                left: 40%;
                top: 56%;
              }
            }
          }
        }
      }
      .modal_close_btn {
        width: 2vw !important;
        height: 2vw !important;
      }
    }
    .color_picker {
      top: 95%;
      right: 0;

      > div {
        &:first-child {
          left: 82% !important;
        }
      }
    }
    .primary_action {
      font-size: 1.4vw;
    }
    .default_outlined_btn {
      padding: 0 3.755vw;
      font-size: 3.755vw;
      border-width: 0.292vw;
      border-bottom-width: 0.583vw;
      border-radius: 1.467vw;
      min-height: 9.915vw;
    }
    h1.page_title {
      font-size: 4.7vw;
    }
    .teams_and_people p.go_back_btn {
      border-bottom: none;
      margin: 0 1.042vw 2.4vw 0;
      font-size: 2.365vw;
      text-decoration: underline;
      @include text-styles(0.82vw, 500, $btn-secondary-color, left, $primary-font-family);
      .back_btn_value {
        border-bottom: 0.05vw solid #504979B3;
      }
      .left_arrow {
        display: none;
      }
    }
    .multiple_select_checkbox {
      width: 16px;
      height: 16px;

      .ant-checkbox {
        .ant-checkbox-inner {
          width: 16px;
          height: 16px;
          border-color: #d9d9d9 !important;
          background-color: #fafafa;

          &::after {
            top: 50%;
            width: 5.71428571px;
            height: 9.14285714px;
          }
        }

        &-checked {
          .ant-checkbox-inner {
            background-color: #9791BA;
          }
        }
      }
    }
    .toggle {
      width: 13vw;
      height: 3vw;

      .ant-switch-handle {
        width: 5.5vw;
        height: 5.5vw;
        top: -1.4vw;
      }
    }
    .total_count {
      font-size: 3.2vw;
      padding: 1.5vw 2vw;
      margin-right: 4.5vw;
    }
    .user_credentials_sect .user_info {
      h2 {
        font-size: 4.767vw;
        max-width: 60vw;
        margin-left: 2.933vw;
      }
      img {
        width: 8vw;
        height: 8vw;
      }
      div.avatar_skeleton, div.avatar_skeleton span {
        width: 8vw;
        height: 8vw;
      }
    }
    .progress_tooltip_custom .ant-tooltip-inner {
      padding: 1.05vw 2.3vw;
      font-size: 3.733vw;
    }
    .form_item {
      width: 100%;
      .ant-picker .ant-picker-input input {
        font-size: 1.6vw;
        &::placeholder {
          font-size: 1.26vw;
        }
      }
      .goal_input, .ant-picker {
        font-size: 1.6vw;
        &::placeholder {
          font-size: 1.26vw;
        }
      }
      label {
        font-size: 1.9vw;
      }
    }
    .ant-select-selection-search-input {
      font-size: 18px !important;
    }
    #modal-root {
      .modal_container {
        .search_box {
          .secondary_search {
            padding: 0 8vw 0 13vw;
            height: 12vw;
            font-size: 3.2vw;

            &::placeholder {
              font-size: 3.2vw;
            }
          }

          .search_icon {
            font-size: 4.8vw;
            left: 5%;
            top: 30%;
          }

          .clear_search {
            width: 3.5vw;
            height: 3.5vw;
          }
        }

        .apm_modal {
          width: $modal-mobile-size;
          max-width: $modal-mobile-size;
          min-width: $modal-mobile-size;
          @include flex-box(center, flex-start, column);

          .modal_content {
            padding: 0 5.05vw 4vw;

            .template_filter {
              align-items: center;

              input {
                @include text-styles(3.2vw, 500, $role-color, left, $primary-font-family);

                &::placeholder {
                  @include text-styles(3.2vw, 500, $role-color, left, $primary-font-family);
                }
              }
            }
          }
        }
      }
    }
    .add_goal_form .add_goal_modal .input_section .actions_sect button.add_goal {
      font-size: 1.72vw;
    }
    .add_goal_form button.advanced_settings {
      font-size: 1.5vw;
    }
    button.cancel_btn {
      font-size: 2.52vw;
    }
    .cancel_edit_btn_kr {
      font-size: 3.2vw!important;
      margin-top: 3vw;
      margin-right: 0;
      margin-bottom: 0;
      padding-top: 1.5vw!important;
      padding-bottom: 0.7vw!important;
    }
    .main_actions .action_item p {
      font-size: 1.76vw;
    }
    .sort_dropdown {
      .sort_option {
        font-size: 1.2vw;
      }
    }
    .tooltip_custom{
      .ant-tooltip-inner {
        font-size: 3.5vw;
        padding: 1.8vw 2.3vw;

        pre {
          font-size: 3.5vw;
        }
      }
    }
    .tooltip_info {
      padding: 1.5px 1.867vw;
      font-size: 3.2vw;
      margin-left: 2.167vw;
    }
    .info {
      padding: 1px 1.85vw;
      font-size: 3.2vw;
      margin-left: 1.5vw;
    }
    // weekly items
    .specific_user_main_sect, .accomplishments_mine {
      .adding_sect .actions_sect .advanced_actions .deadline_title {
        font-size: 1.8vw;
      }
    }
    .ws_list .interactive_list .interactive_list_item > div .item_actions .deadline_sect {
      .timer {
        width: 6.45vw;
        height: 6.45vw;
        margin-right: 0.88vw;
      }
      .deadline_title {
        font-size: 3.2vw;
      }
    }

    .weekly_status, #weekly_items_sect {
      .interactive_list_item {
        .interactive_sub_list {
          .comments_list li {
            position: relative;
            align-items: flex-start;
            flex-direction: column;
            padding: 4vw 6vw;

            .comments_list_inner_span {
              margin-bottom: 15vw;
            }

            span {
              font-size: 3.2vw;
            }
          }
        }
      }
    }
    .comments_list > li > div:last-child {
      position: absolute;
      right: 6vw;
      top: 5vw;
      width: auto;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    .comments_list > li > div {
      width: 100%;
      flex-wrap: wrap;

      &:first-child {
        width: 100% !important;
      }

      &.comment_sender {
        width: 100%;
        > div {
          width: unset;
        }
      }

      .creator_img_wrapper {
        margin-right: 1.5vw;
      }

      img {
        width: 6.45vw;
        height: 6.45vw;
      }

      div.avatar_skeleton {
        width: 6.45vw;
        height: 6.45vw;

        span {
          width: 6.45vw;
          height: 6.45vw;
        }
      }

      h3 {
        width: 100%;
        line-height: 1.2;
        margin-right: 0;
        font-size: 3.733vw;
        max-width: 35vw;
        @include text-elipses();
      }

      p {
        width: 100%;
        font-size: 3.2vw;
        padding: 3vw 0 0;
        line-height: 1.5715;

        a {
          font-size: 3.733vw;
        }
      }

      .actions_menu {
        margin-left: 1.5vw;
        min-width: 4vw !important;
        min-height: 4vw !important;
        width: 4vw !important;
        height: 4vw !important;
      }
    }
    .comment_container {
      align-items: flex-start;
      flex-direction: column;
      padding: 4.928vw;
      border-width: 0.292vw;

      .comment_input_area {
        align-items: flex-start;
        margin-top: 3vw;
        line-height: 1.15;

        .datalist-list {
          max-height: 40vw;
        }

        textarea {
          margin: 0;
          border-radius: 0;
          font-size: 3.733vw;
          max-height: unset !important;
          padding: 0;

          &::placeholder {
            font-size: 3.733vw;
          }
        }
      }

      .primary_action {
        margin: 0;
        padding: 2.133vw 3.12vw;
        font-size: 3.2vw;
        border-radius: 1.760vw;
      }

      img {
        margin: 0;
        width: 10vw;
        height: 10vw;
      }

      div.avatar_skeleton {
        margin-top: 0;
        width: 10vw;
        height: 10vw;

        span {
          width: 10vw;
          height: 10vw;
        }
      }
    }
    .sort_dropdown {
      .sort_option {
        padding: 2vw 3vw;
        font-size: 3.733vw;
      }
    }
    p.single_sect_secondary_text {
      font-size: 3.2vw;
    }
    .advanced_actions_menu {
      li {
        padding: 2.3vw 2.9vw;
        span {
          font-size: 2.9vw;
          span {
            font-size: 16px !important;
          }
        }
      }

      &:before {
        display: none;
        left: calc(70% - 0.55vw);
        top: -1.3vw;
        border-left: 3.3vw solid transparent;
        border-right: 3.3vw solid transparent;
        border-bottom: 3.3vw solid #605E9F;
      }
    }
    .date_interval_container {
      align-items: flex-start;
      span.date_title {
        font-size: 2.2vw;
        padding: 0.1vw 0.2vw 0 0;
      }
    }
    .empty_data_warning {
      border-radius: 0.88vw;
      padding: 2vw;

      h3 {
        line-height: 1.5;
        font-size: 3.2vw;
      }
    }
    .input_field_validation {
      font-size: 3.2vw;
    }
    .weekly_status {
      .page_control_panel {
        h1 {
          top: -6.467vw;
        }
        .weekly_status_tabs {
          .ant-tabs-nav {
            .ant-tabs-nav-list {
              margin: 0;
            }
          }
        }
      }
    }
    .page_control_panel {
      h1 {
        &.hidden {
          display: none;
        }
      }
      .weekly_status_tabs {
        .ant-tabs-nav {
          padding-left: 0 !important;

          .ant-tabs-tab {
            font-size: 3.8vw;
            padding-bottom: 4.267vw;
            padding-left: 1.8vw;
            padding-right: 1.8vw;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }

    p.secondary_title {
      font-size: 4.3vw;
    }
  }

  .app_content {
    flex: 1;
    //margin-left: 7.678vw;
  }
}

@include mobile-media {
  body {
    input, textarea, select {
      font-size: 16px!important;

      &::placeholder {
        font-size: 16px!important;
      }
    }

    .apm_modal.filter_modal {
      .modal_content {
        width: 100%;
        padding-top: 0;

        .filter_form {
          width: 100%;
          @include flex-box(flex-start, flex-start, column);
          margin-bottom: 0;
        }
      }
    }

    .filter_form {
      margin-bottom: 0;
      flex-wrap: wrap;
      row-gap: unset;

      .main_filter_actions {
        display: flex;
        @include flex-box(center, center, column);
        flex-wrap: nowrap;
        width: 100%;
        height: unset !important;
      }

      .filter_item {
        width: 100%;
        max-width: unset;
        height: unset;
        @include flex-box(center, center, row);
        flex-wrap: nowrap;
        padding: 2vw;
        margin-bottom: 4vw;
        margin-right: 0;
        min-height: 12.075vw;

        p {
          font-size: 16px;
          width: unset;
          margin-right: 1vw;
          margin-bottom: 0;
        }

        input {
          width: 100%;
          font-size: 3.2vw;
          max-width: unset;

          &::placeholder {
            font-size: 3.2vw;
          }
        }

        .ant-select {
          width: 100% !important;

          .ant-select-clear {
            font-size: 4.8vw;
            width: 4.8vw;
            height: 4.8vw;
            top: 3vw;
            right: 0vw;

            span {
              background-color: $grey-background-color;
            }
          }
        }

        &.user_select {
          align-items: center;
          justify-content: center;
          flex-direction: row;

          .ant-select {
            height: unset;
            .ant-select-selector {
              width: 100%;

              .ant-select-selection-item {
                max-height: 8.741vw;
                height: unset;
                padding: 1.173vw 2.933vw;
                font-size: 4.811vw;
                border-radius: 5.333vw;

                .ant-select-selection-item-content {
                  font-size: 3.2vw;
                  margin-right: 1.3vw;
                }
                .ant-select-selection-item-remove {
                  font-size: 2.7vw;

                  span {
                    display: flex;
                    @include flex-box(center, center, row);
                    line-height: 1;
                  }
                }
              }

              .ant-select-selection-placeholder {
                font-size: 16px;
              }

              .ant-select-selection-search {
                max-width: unset;
                width: unset !important;

                .ant-select-selection-search-input {
                  min-width: 14vw !important;
                  font-size: 16px !important;

                  &::placeholder {
                    font-size: 16px !important;
                  }
                }
              }

              .ant-select-selection-search-mirror {
                width: 100%;
              }
            }
          }
        }

        &.user_role_filter_sect {
          .ant-select {
            min-height: 7.301vw;
            max-height: 7.301vw;

            &.role_select {
              .ant-select-selector .ant-select-selection-item {
                padding: 0;
              }
            }

            .ant-select-selector {
              .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                display: flex;
                align-items: center;
              }
            }
          }
        }

        &.gamification_action_filter_sect {
          .ant-select {
            min-height: 7.301vw;
            max-height: 7.301vw;

            &.action_select {
              .ant-select-selector .ant-select-selection-item {
                padding: 0;
              }
            }

            .ant-select-selector {
              .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                display: flex;
                align-items: center;
              }
            }
          }
        }

        &.deadline_select .ant-picker {
          width: 100%;

          .ant-picker-input {
            .ant-picker-suffix {
              svg {
                width: 5.8vw;
                height: 6.15vw;
              }
            }

            input {
              font-size: 3.2vw;

              &::placeholder {
                font-size: 3.2vw;
              }
            }
          }
        }
      }

      .main_filter_actions-btns {
        width: 100%;
        display: flex;
        @include flex-box(center, center, column);
      }

      .action_btn {
        height: unset;
        width: unset;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 6.6px;
        font-size: 3.867vw;
        margin-right: 0;
        padding: 3vw 8.333vw;
        margin-left: auto;
      }

      .close_filter_modal {
        font-size: 3.467vw;
        margin: 2.6vw 0 0 auto;
      }
    }

    .nested_tabs {
      margin: 4vw 0;

      .ant-tabs-tab {
        margin: 0 1vw;
        padding: 0.55vw 0.46vw 1.7vw 0.46vw;
        letter-spacing: 0;
        font-size: 3.4vw!important;
      }
    }

    .page_control_panel {
      padding-top: 0;
      background: none;
      flex-wrap: wrap;
      width: unset;
      flex: unset;
      align-items: stretch;
      border: none;
      box-shadow: none;

      .chart-tile {
        width: 100%;
        flex: unset;
        border: 0.05vw solid #EAE4F4;
        background-color: white;
      }

      .weekly_status_tabs {
        &.hidden {
          .ant-tabs-nav {
            display: none;
          }
        }

        .ant-tabs-nav {
          padding-left: 0 !important;
          margin-bottom: 3vw;
          margin-left: -3.5vw;
          margin-right: -3.5vw;
          width: calc(100% + 7vw);

          .ant-tabs-nav-list {
            justify-content: space-between;
          }

          .ant-tabs-tab {
            font-size: 3.8vw;
            padding-bottom: 3.320vw;

            &-active::after {
              height: 0.533vw;
            }
          }

          .ant-tabs-nav-operations {
            display: none;
            padding: 0;
          }
        }
      }
    }

    .team_filter_sect {
      .team_filter {
        max-height: 42vw;
      }
    }

    .goals_progress_bar {
      height: 1.272vw;
      margin: 0 0 5.661vw;
      width: 100%;
    }

    .ant-notification {
      margin: 0;
      max-width: unset;
      width: 95%;
      right: 2.3% !important;

      .ant-notification-notice {
        width: 100%;
      }
    }

    .specific_user_main_sect, .accomplishments_mine {
      .ws_single_sect {
        background-color: transparent;
        border: none;

        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
      .section_heading {
        padding: 4vw;
        font-size: 3.733vw;
        background-color: #ffffff;
        border: 1px solid #eae4f4;
        box-shadow: 0 0 0.7vw rgb(206 185 232 / 16%);
      }
      .user_credentials_sect {
        @include flex-box(space-between, center, row);
        margin-top: 0;
        margin-bottom: 0.84vw;

        &.specific_user_credentials {
          @include flex-box(flex-end, baseline, column);

          & > div:first-child {
            .specific_user {
              margin-bottom: 3.5vw;
            }
          }
        }

        & > div:first-child {
          position: relative;
          display: inline-flex;
          @include flex-box(flex-start, flex-start, column);
          padding-left: 0;

          p.go_back_btn {
            margin-bottom: 2.400vw;
            border-bottom: none;
            font-size: 3.967vw;
            border-bottom: 0.05vw solid #504979B3;
            margin-right: 1.042vw;
            color: #561884;
            .left_arrow {
              display: none;
            }

            .back_btn_value {
              margin-bottom: 0;
            }
          }
          .specific_user {
            margin-bottom: 0;

            h2 {
              font-size: 4.267vw;
              max-width: 80vw;
            }
            img {
              width: 8vw;
              height: 8vw;
              margin-right: 2.5vw;
            }
          }
        }

        .progress_circle {
          display: none;
        }
        .progress_bar {
          width: calc(100% + 7vw);
          margin: 0 -3.5vw;
          background-color: #EAE4F4;
          border-radius: 0.651vw;
          display: flex;
          margin-top: 0;
          .progress_bar_inner {
            background-color: $tab-primary-color;
            border-radius: 0.651vw;
            height: 1.333vw;
          }
        }

        & > div:nth-of-type(2) {
          span {
            font-size: 3.733vw;
          }
          .ant-progress-inner {
            width: 13.333vw !important;
            height: 13.333vw !important;
          }

        }
      }
      .week_satisfaction_sect {
        padding: 4vw !important;
        background-color: #ffffff;

        h3 {
          font-size: 3.733vw;
        }

        div.mine_satisfaction_heading {
          margin-bottom: 3vw;

          p {
            margin-top: 1.5vw;
            font-size: 3.2vw;
          }
        }

        div.satisfaction_rate_main {
          .satisfaction_rate_sect {
            display: flex;
            margin-right: 3.6vw;

            svg {
              width: 5.62vw;
              height: 5.62vw;
            }
          }
          .checked_stars_count {
            line-height: initial;
            font-size: 4.3vw;
          }
        }
      }
      .ws_plans, .ws_progress, .ws_challenges, .ws_coming_up {
        .title_icon {
          min-width: 4vw;
          min-height: 4vw;
          width: 4vw;
          height: 4vw;
          margin-right: 2.400vw;
        }
      }
      .ws_single_sect {
        .ws_byUser_items_loader {
          margin: 2vw 0;
        }
      }

      .actions_sect {
        .advanced_actions {
          .action_item {
            margin-left: 2.35vw;
            width: 4.696vw;
            height: 4.696vw;
          }
          .emoji_selection {
            width: 5vw;
            height: 5vw;
          }
          .goal {
            width: 7vw;
            height: 7vw;

            .goal_icon {
              width: 5.5vw;
              height: 5.5vw;
            }
          }
          .fastener {
            height: 5.1vw;
            width: 4vw;

            .count {
              display: flex;
              @include flex-box(center, center, row);
              width: 5vw;
              height: 5vw;
              font-size: 2.6vw;
              padding: 0;
              right: -35%;
              top: -50%;
            }
          }
          .deadline {
            width: 6.067vw;
            height: 6.067vw;
          }
          .deadline_title {
            display: none;
          }
        }
        button.add_accomplishment {
          line-height: 4.8vw;
          font-size: 3vw;
          border-radius: 1.76vw;
          margin-left: 1.75vw;
          padding: 1.8vw 3.7vw;
        }
      }

      .adding_main_sect {
        margin: 2.5vw 0;
        .adding_sect {
          padding: 2vw;
          input.add-field {
            padding: 0;
            font-size: 3.2vw !important;
            &::placeholder {
              font-size: 3.2vw;
            }
          }
        }
      }

      .invalid {
        .error_sect {
          font-size: 3.2vw;
          padding-top: 1vw;
        }
      }
    }

    .goals_charts_section, .pulse_point_charts_section, .statistics_chart {
      background: none;
      flex-wrap: wrap;
      width: unset;
      flex: unset;
      align-items: stretch;
      border: none;
      box-shadow: none;

      .chart_tile {
        position: relative;
        width: 100%;
        flex: unset;
        border: 1px solid $primary-border-color;
        background-color: #ffffff;
        padding-top: 5vw;

        &.problems_tile {
          .chart_info_sect {
            .ws_icon {
              width: 4vw !important;
              height: 4vw !important;
            }
          }
        }

        .chart_info_sect {
          padding-left: 5.600vw;
          margin-bottom: 3vw;

          p {
            display: flex;
            @include flex-box(flex-start, center, row);
            font-size: 3.6vw;
            padding-left: 0;

            .ws_icon {
              position: static;
              width: 4vw;
              height: 4vw;
              margin-right: 1.6vw;
              margin-top: 0;
            }
          }
          span {
            margin-top: 1vw;
            line-height: initial;
            font-size: 3.2vw;
          }
          .percentage_value {
            position: absolute;
            margin-top: 0;
            top: 6.667vw;
            right: 5.6vw;
            font-size: 4.3vw;
          }
          .stars_sect {
            position: absolute;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            top: 6.267vw;
            .satisfaction_rate_sect {
              display: flex;
              margin-right: 4.267vw;
            }
          }
        }
      }
    }

    .timeline_list {
      li {
        .content_second_sect {
          display: none;
        }

        .message_log_sect {
          p {
            font-size: 1.833vw;
          }
        }
      }
    }

    .date_interval_container {
      width: 100%;
      @include flex-box(center, center, row);
      top: 16vw;
      right: 0;
      padding: 0 3.5vw;
      max-height: 12.533vw;

      .date_interval_skeleton {
        border-radius: 1.76vw 1.76vw 0 0;
        width: 100%;
        height: 12.533vw;
      }

      .week_picker {
        height: 12.533vw;
        width: 100%;
        border-width: 0.267vw;
        border-radius: 1.76vw 1.76vw 0 0;

        .interval_picker {
          width: 100%;
          height: 100%;
          display: flex;
          @include flex-box(center, center, row);
          padding: 0;

          .ant-select-arrow {
            display: none;
          }

          > div {
            width: 100%;
            height: 100%;
            display: flex;
            @include flex-box(center, center, row);
            padding: 0 17vw;

            input {
              font-size: 3.733vw;
            }

            .ant-select-selection-item {
              width: 100%;
              padding-right: 0;
              font-size: 3.733vw;
              border-width: 0.583vw;
            }
          }
        }
        .left_arrow_container, .right_arrow_container {
          padding: 0 5.223vw;
          height: 100%;

          .arrow {
            min-width: 2.933vw;
            min-height: 2.933vw;
            width: 2.933vw;
            height: 2.933vw;
          }
        }
      }
    }

    .ws_custom_status_bar {
      height: 5.333vw;
      margin-top: 3vw;
      margin-bottom: 3vw;

      div {
        font-size: 2.933vw;
        border-radius: 0.533vw;
      }
    }

    .custom_pagination {
      min-height: 12.325vw;

      li {
        width: 9.347vw;
        height: 9.347vw;

        &.ant-pagination-item-active {
          width: 10.837vw;
          height: 12.328vw;

          a {
            font-size: 4.080vw;
          }
        }

        a {
          font-size: 3.587vw;
        }
      }
    }

    // Goals
    .goals_status_info_main {
      .goals_status_list {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 2.667vw;
        padding-bottom: 0;

        li.sort_able {
          width: 48%;
          margin: 0;
          flex-wrap: wrap;
          padding: 2vw 4.5vw;

          &:not(:nth-last-child(-n+2)) {
            margin-bottom: 2vw;
          }

          .goal_status_info_progress_bar {
            width: 100%;
            background-color: $primary-border-color;
            border-radius: 5px;
            margin-top: 1.733vw;
            order: 1;

            .goal_status_info_progress_bar_inner {
              height: 0.800vw;
              border-radius: 5px;
            }
          }
          .goal_count {
            font-size: 5.8vw;
            margin-left: 0;

            span.indicator {
              width: 1.583vw;
              height: 1.583vw;
              right: -1vw;
            }
          }
          p {
            font-size: 3.2vw;
            margin-top: 0;
            margin-left: 1.5vw;
          }
        }
      }
    }
    /////////////

    .timeline_list {
      li {
        padding: 3vw;
        margin-bottom: 2vw;

        .content_first_sect {
          flex-wrap: wrap;

          img {
            width: 8vw;
            height: 8vw;
            margin-right: 2vw;
          }
          div.avatar_skeleton {
            width: 8vw;
            height: 8vw;
            margin-right: 2vw;

            span {
              width: 8vw;
              height: 8vw;
            }
          }
          h3 {
            max-width: 87%;
            width: unset;
            font-size: 3.755vw;
            margin-right: 0;
          }
          .action_date {
            order: 1;
            display: block;
            font-size: 3.2vw;
            text-align: left;
            margin-left: 1vw;
            padding-left: 0;
          }
          .action_ip {
            display: block;
            margin-left: auto;
            order: 1;
            @include text-styles(3.2vw, 500, $role-color, left, $primary-font-family);
          }
          .message_log_sect {
            width: 100%;
            margin: 2vw 0;

            .plan_icon {
              width: 1.302vw;
              height: 1.302vw;
            }

            .goal_icon {
              width: 5vw;
              height: 5vw;
            }

            .weekly_item_icon {
              width: 5vw;
              height: 5vw;
            }

            .key_result_icon {
              width: 5vw;
              height: 5vw;
            }

            .file_icon {
              width: 4.5vw;
              height: 4.5vw;
            }

            p {
              font-size: 3.2vw;
              margin-left: 0;
              margin-top: 2.133vw;
              max-width: 100%;
              overflow: unset;
              white-space: normal;
              flex-wrap: wrap;
            }
          }
          p {
            font-size: 1.953vw;
            width: 100%;
            max-width: 65vw;
            margin-left: 1.2vw;
          }
        }
        .content_second_sect {
          display: none;
          .action_date {
            display: none;
          }
        }
        //span.link {
        //  font-size: 1.953vw;
        //}
      }
    }

    // Reviews
    .review_sect, .secondary_reviews_info_sect {
      form {
        display: flex;
        @include flex-box(flex-end, center, column);
        width: 100%;
      }

      .user_credentials_sect {
        .user_info {
          @include flex-box(flex-start, flex-start, row);
          flex-wrap: wrap;

          .user_info_go_back {
            margin-bottom: 2.400vw;
            font-size: 4.267vw;

            .user_info_go_back_text {
              font-size: 3.967vw;
              line-height: 1;
              border-bottom: 0.05vw solid $btn-secondary-color;
              color: $btn-secondary-color;
              cursor: pointer;
              font-weight: 500;
            }
            .user_info_go_back_slash {
              display: none;
            }
          }

          h2 {
            margin-left: 0;
            min-height: 8vw;
            display: flex;
            align-items: center;
            font-size: 4.767vw;
            max-width: unset;
            width: calc(100% - 10vw);
            overflow-x: initial;
            text-overflow: initial;
            white-space: initial;
          }

          img {
            margin: 0 !important;
            width: 8vw;
            height: 8vw;
          }
          div.avatar_skeleton, div.avatar_skeleton span {
            margin: 0 !important;
            width: 8vw;
            height: 8vw;
          }
        }
      }

      div.secondary_single_sect {
        margin-bottom: 2.604vw;
      }
      h3.secondary_single_sect_primary_text {
        font-size: 3.733vw;
        padding: 4vw;
      }
      .reviews_list {
        margin-top: 0;

        li.list_item {
          padding: 4vw;
          flex-wrap: wrap;

          img {
            width: 6.933vw;
            height: 6.933vw;
            margin-right: 4.602vw;
          }
          .satisfaction_rate_sect {
            margin-right: 1.5vw;

            svg {
              width: 4vw;
              height: 4vw;
            }
          }
          p {
            width: 100%;
            font-size: 3.2vw;
            margin-right: 0;
            margin-top: 2vw;
          }
          .date_of_review {
            font-size: 3.2vw;
            margin: 1vw 0 0 0;
          }
          .rate-main-item-content {
            padding: 2.93vw 4vw;
            flex-wrap: wrap;

            > div {
              width: 100%;
            }

            .satisfaction_rate_sect {
              display: flex;
            }

            .explanation_icon {
              width: 5.5vw;
              height: 5.5vw;
            }

            .levelProgress_sect {
              width: 43vw;
              margin-right: 2vw;
            }
          }

          .interactive_sub_list {
            li {
              padding: 2vw 4vw;
              font-size: 3.2vw;
            }
          }
        }
        span.level_indicator, span.grade_indicator {
          font-size: 4.267vw;
        }
      }

      .overall_sect {
        padding: 0 4vw 4vw;
        @include flex-box(flex-start, center, row);

        .single_sect_secondary_text {
          display: none;
        }

        &.range_answers {
          @include flex-box(flex-start, flex-start, column);

          p {
            margin: 0;
          }
        }
        &.grade_answers {
          .satisfaction_rate_sect {
            display: flex;
            margin-bottom: 0;
            margin-right: 2vw;

            svg {
              width: 4.5vw;
              height: 4.5vw;
            }
          }
          p {
            order: 1;
          }
        }

        span.grade_indicator {
          font-size: 4.8vw;
          margin: 0;
        }

        p {
          font-size: 3.2vw;
        }
      }

      .review_form {
        div.single_sect {
          padding: 3.646vw 3.906vw 5.208vw;

          .multi_choice_selection {
            label {
              margin-bottom: 2.865vw;
            }

            .ant-checkbox-inner {
              width: 6.667vw;
              height: 6.667vw;
            }
          }

          .single_choice_selection {
            label {
              margin-bottom: 2.865vw;
              &:last-child {
                margin-bottom: 0;
              }
            }

            .choice_item {
              span.ant-radio-inner {
                width: 7.621vw;
                height: 7.621vw;
              }
            }
          }

          .rate_with_explanation {
            .satisfaction_rate_sect {
              display: flex;

              li {
                margin-right: 2.813vw;
                svg {
                  width: 5.933vw;
                  height: 5.933vw;
                }
              }
            }

            .hidden_input_field {
              &.show, &.hidden {
                padding: 2.204vw;
                height: unset;

                input {
                  font-size: 3.2vw;
                  height: unset;

                  &::placeholder {
                    font-size: 3.2vw;
                  }
                }
              }
            }
          }

          .input_field {
            padding: 2vw;
            height: unset;

            input, textarea {
              padding: 0;
              font-size: 3.2vw;
              height: unset;

              &::placeholder {
                font-size: 3.2vw;
              }
            }
          }
        }

        h3.single_sect_primary_text {
          font-size: 4.800vw;
        }

        .review_submit_btn {
          margin-left: auto;
          margin-top: 0;
          padding: 3vw 4vw;
          font-size: 3.2vw;
          max-width: unset;
        }
      }
    }

    .submit_btn {
      padding: 2.497vw 3.516vw;
      font-size: 3.2vw;
      max-width: unset;
      border-radius: $border-radius-mobile;
    }

    //// Already reviewed reviews page

    .check_icon {
      font-size: 2.933vw;
      width: 4.4vw;
      height: 4.4vw;
      bottom: -0.4vw;
      right: -0.7vw;
    }

    .reviewed_users_info {
      .reviewed_users_list {
        li {
          width: 7.467vw;
          height: 7.467vw;

          img {
            width: 7.467vw;
            height: 7.467vw;
          }
        }
      }
      .details_btn {
        font-size: 3.387vw;
      }
    }

    .reviews {
      &.review {
        &.hidden {
          .page_control_panel {
            h1 {
              display: none;
            }
          }
        }

        div {
          .single_sect {
            padding: 4vw !important;
            margin-bottom: 3.2vw;

            &_primary_text {
              font-size: 3.733vw;
              margin-bottom: 1.65vw;
            }

            .description {
              font-size: 3.2vw;
              margin-bottom: 0.65vw;
            }

            .grade_sect {
              h2 {
                font-size: 4.267vw;
              }

              ul {
                li {
                  margin-right: 1.6vw !important;
                }
                svg {
                  width: 5vw;
                  height: 5vw;
                }
              }
            }

            > div {
              flex-wrap: wrap;
            }

            .received_reviewed_explanation {
              font-size: 3.2vw;
              width: 100%;
              margin-top: 2vw !important;
            }

            .single_choice_selection {
              label {
                .ant-radio {
                  .ant-radio-inner {
                    width: 7.467vw;
                    height: 7.467vw;
                    &:after {
                      left: 32.5%;
                      top: 16%;
                      font-size: 4.480vw;
                    }
                  }

                  & + span {
                    font-size: 3.2vw;
                  }
                }
              }
            }

            .multi_choice_selection {
              label {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    width: 6.667vw;
                    height: 6.667vw;
                    &:after {
                      top: 42%;
                      left: 18%;
                      width: 2vw;
                      height: 4vw;
                    }
                  }

                  & + span {
                    font-size: 3.2vw;
                  }
                }
              }
            }

            h4 {
              font-size: 4.267vw;
              max-width: 80vw;
            }
          }
        }
      }
    }

    .form_item, .form_parent {
      &.invalid {
        .error_sect {
          height: unset;
          font-size: 3.2vw;
          padding-top: 1vw;
        }
      }
    }

    .emoji_pallet_modal {
      left: 14%;

      .emoji-mart .emoji-mart-bar {
        .emoji-mart-preview {
          .emoji-mart-preview-data {
            .emoji-mart-title-label {
              font-size: 3.8vw;
            }
          }
        }
      }

      .emoji-mart-search input {
        &::placeholder {
          font-size: 4.267vw;
        }
      }
    }

    .user_role_filter_sect {
      .role_select {
        .ant-select-selection-item {
          font-size: 4.85vw;
        }
      }
    }

    .gamification_action_filter_sect {
      .action_select {
        .ant-select-selection-item {
          font-size: 4.85vw;
        }
      }
    }

    .ws_single_sect_empty {
      .empty_data_warning {
        width: 100% !important;
      }
    }

    .weekly_item_created_date {
      font-size: 3.2vw;
      position: static;
    }
  }

  .leaderboard_star {
    width: 7vw;
    height: 7vw;
  }

  .gamification_top_logo {
    top: -1vw;
  }

  .gamification_logo_with_total_count {
    margin-left: 3vw;

    img {
      width: 9.7vw;
    }
  }

  .gamification_top_container {
    margin-left: 2.5vw;
  }

  .gamification_top {
    top: -2vw;

    .gamification_total_count {
      bottom: 2.75vw;
    }
  }

  .gamification_total_count {
    left: 3.6vw;
    bottom: -9px;
    font-size: 2.667vw;
    border-radius: 3px;
  }

  .enps_sup {
    top: -0.5em!important;
  }
}
