@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/vars";

.error_page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  @include flex-box(center, center, row);

  h2 {
    margin-bottom: 0;
    line-height: initial;
    @include text-styles(1.49vw, 500, $user-profile-name-color, left, $primary-font-family);
  }
}

@include tablet-media {
  .error_page {
    h2 {
      font-size: 3vw;
    }
  }
}

@include  mobile-media {
  .error_page {
    h2 {
      font-size: 6vw;
    }
  }
}