@import '../../../assets/styles/mixins';

.page_loader_container {
  width: 100%;
  min-height: calc(100vh - 14.5vw);
  display: flex;
  @include flex-box(center,center,column);
}

.page_loader {
  width: 2.545vw;
  height: 2.545vw;
  border: 3px solid #5678FA;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include tablet-media {
  .page_loader {
    width: 6.771vw;
    height: 6.771vw;
  }
}

@include mobile-media {
  .page_loader {
    width: 13.867vw;
    height: 13.867vw;
  }
}
