//breakpoints
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1440px;

// font-family
$primary-font-family: 'Roboto', sans-serif;

// colors
$primary-color: #28139D;
$company-sect-color: #311BB6;
$review-btn-color: #3932E0;
$review-btn-color-hover: #6255FF;
$review-btn-color-active: #3F2BAF;
$review-btn-disabled-color: #9290B7;
$avatar-border-color: #6794DD;
$user-profile-name-color: #100841;
$action-btn-color: #58569D;
$btn-secondary-color: #561884;
$btn-secondary-color-hover: #742FA7;
$secondary-color: #670883;
$company-badge-color: #C86AFA;
$tab-primary-color: #7D2DB9;
$tab-primary-color-hover-bg: #F2ECF7;
$tab-primary-color-active-bg: #EFDDFF;
$filter-users-color: #491F74;
$role-color-secondary: #A277C7;
$filter-remove-btn-color: #B593B4;
$box-shadow-primary: 0px 4px 10px #C1A4D329;
$box-shadow-secondary: 0 0 0.7vw #CEB9E829;
$secondary-item-background: rgba(234, 228, 244, 0.15);
$box-shadow-selected: 0 0 0.7vw #7440B553;
$dashboard-background-primary: #F7F9FD;
$account-help-section-background: #F8F7FC;
$dashboard-background-secondary: #FCFCFF;
$primary-border-color: #EAE4F4;
$switcher-border: #C8BEDB;
$switcher-active-border: #4365E8;
$switcher-active-track-road: #C9D3FC;
$modal-secondary-background: #FBFBFE;
$secondary-border-color: #D2CAE0;
$info-icon-background: #a159f9;
$hierarchy-btn-color: #827DA0;
$secondary-border-color-with-opacity: rgba(211, 204, 225, 0.7);
$secondary-menu-item-color: #FCFBFD;
$filter-form-placeholder-color: #A39FBA;
$selected-item-background-color: #FFFFFFB3;
$adding-sect-background: #fff;
$icons-border-secondary-color: #9388d0;
$adding-sect-border-color: #b19bd6;
$selected-linked-goal: rgba(96, 128, 250, 0.2);
$adding-sect-input-color: #8A6CA3;
$select-button-background: #914AC6;
$select-button-background-hover: #B14DFC;
$select-button-background-active: #662596;
$adding-sect-btn-background: #9C99CC;
$selected-item-border-color: #bfb4d8;
$role-color: #504979B3; // Todo convert to rgba
$grey-background-color: #EBE7F580;
$on-track-indicator-color: #5678FA;
$dropdown-background-color: #605E9F;
$dropdown-menu-item-border-color: #8c83c3b3;
$on-track-secondary-indicator: #56CEFA;
$progress-bar-color-1: #43FFE9;
$progress-bar-color-2: #586BFF;
$rate-stars-color: #FF9D00;
$info-alert-border: #CE8A1D;
$info-alert-background: #FAF4EA;
$info-alert-shadow: #E8436233;
$info-alert-color: #34260D;
$badge-direct: #C79377;
$secondary-text-color: #A29BC7;
$secondary-background-section-color: #F4EFFF;
$card-border-color: #BBABDB;
$badge-peer: #8173E3;
$badge-admin: #C973B2;
$at-risk-indicator-color: #FFB743;
$mandatory-field-color: #EA9001;
$text-field-color: #187384;
$grading-field-color: #841852;
$rating-field-color: #0F8400;
$enps-field-color: #56ABDC;
$long-text-color: #183984;
$active-navbar-link: #341199;
$dashboard-active-btn: #000CBE;
$dashboard-pagination-btn: #a4a4a4;
$off-track-indicator-color: #FD6E89;
$status-bar-problems-color: #E84362;
$notification-reminder-color: #D357A7;
$exceeded-indicator-color: #67C764;
$in_progress-indicator-color: #5678FA;
$completed-indicator-color: #5a9f58;
$status-bar-progress-color: #51C34D;
$completed-item-border-color: #67c764;
$notification-success-border: #0F8400;
$notification-error-border: #D5405C;
$notification-warning-border: #CE8A1D;
$notification-success-background: #F0F8F2;
$notification-error-background: #F7EDF3;
$notification-warning-background: #FAF4EA;
$inactive-item-background: #F7F4FAD9;
$notification-success-color: #0C3408;
$notification-error-color: #340D1A;
$active-item-color: #572F9A;
$key-results-value-color: #4190CB;
$move-able-tooltip-background: #4BC5F2;
$count-indicator-background: #9791BA;
$secondary-action-btn-background: #6B68B2;
$notify-box-border: #48A246;
$notify-box-background: #E7F7E7;
$notify-box-shadow: #1B8B1629;
$notify-box-color: #3C4A3C;
$notify-box-action-btn-color: #262F26;
$invalid-field-background: #E8436205;
$dashboard-ws-plans-background: #5678fa;
$dashboard-ws-challenges-background: #e84362;
$check-in-creator-color: #655D96;
$check-in-creator-color-hover: #6458AA;
$icon-circle-background: #F6F5F9;
$icon-circle-border-color: #D2CAE0;
$notifications-close-bg: rgba(242, 241, 250, 0.3);
$active-notification-bg: rgba(211, 231, 255, 0.45);
$notification-hover-bg: rgba(211, 231, 255, 0.25);
$not-purchased-status-bg: #B2AFC4;
$footer-bg-color: #f3f4f8;
$footer-font-color: #96a0c3;
$subscription-info-color: #272727;
$downgrade-btn: #6563a9;
$downgrade-btn-hover: #535193;
$downgrade-btn-active: #424080;
$typing-circle-inactive: #767272;
$typing-circle-active: #aba3a3;
$surveys-ex-font-color: #504979;
$audit-trail-icons-inactive: #afafaf;
$audit-trail-icons-hover: #9b8dea;
$strongly-disagree: #dc2626;
$disagree: #f97316;
$neutral: #fbbf24;
$agree: #84cc16;
$strongly-agree: #15803d;
$white_color_with_opacity: rgba(255, 255, 255, 0.9);
$default-survey-color: #d357a7;
$promoters-color: #6CA92A;
$detractors-color: #DD4C3C;
$passives-color: #F7A622;

// sizes
$border-radius-mobile: 6.6px;

$modal-desktop-size: 35vw;
$modal-tablet-size: 70vw;
$modal-mobile-size: 85.909vw;
