@import '../../../../assets/styles/vars';
@import '../../../../assets/styles/mixins';

.login_page_container {
  width: 100%;
  background: transparent linear-gradient(119deg, $primary-color 0, $secondary-color 100%);
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  @include flex-box(center, center, column);

  .form_box_wrapper {
    margin: auto;
  }

  .form_box {
    transform: scale(0.4) rotateX(90deg);
    opacity: 0;
    transition: transform .6s linear, opacity .6s linear;
    background: white;
    display: flex;
    @include flex-box(center, center, column);

    .company_container {
      width: 100%;
      border-bottom: 0.05vw solid $primary-border-color;
      background: white;
      padding: 1.8vw 0;
      display: flex;
      @include flex-box(center, center, row);

      .company_image_wrapper {
        display: flex;
        @include flex-box(center, center, row);

        &_default {
          background: transparent linear-gradient(119deg, $primary-color 0, $secondary-color 100%);
          border-radius: 5px;
          padding: 0.682vw;

          .company_image {
            height: auto !important;
            max-width: 4vw;
          }
        }
      }

      .company_image {
        max-width: 8vw;
        object-fit: contain;
      }

      .company_logo_text {
        display: flex;
        @include flex-box(center, center, row);
        @include text-styles(0.6vw, bold, $primary-color, left, $primary-font-family);
        letter-spacing: 0.24px;
        width: 1.818vw;
        height: 0.955vw;
        text-transform: uppercase;
        border-radius: 1px;
        margin-left: 0.273vw;
        margin-bottom: 0.5vw;
        line-height: initial;
        opacity: 0.8;
        background: white 0 0 no-repeat padding-box;
      }

      div.avatar_skeleton {
        max-width: 10vw;
        width: 100%;

        span {
          max-width: 10vw;
          width: 100%;
          min-height: 5vw;
        }
      }
    }

    .login_form {
      position: relative;
      padding: 3vw 5.94vw 6vw 5.94vw;
      display: flex;
      @include flex-box(center, flex-start, column);

      .wrong_credentials {
        width: 100%;
        transition: 0.4s;
        height: 0;
        opacity: 0;
        display: flex;
        @include flex-box(center, center, column);

        h3 {
          margin-bottom: 0;
          line-height: 1;
          @include text-styles(0.89vw, 500, $status-bar-problems-color, center, $primary-font-family);
        }

        .warning {
          margin-bottom: 0.5vw;
          @include icon-styles(2vw, 2vw, "alert.svg", contain);
        }
      }

      .wrong_credentials.visible {
        opacity: 1;
        height: auto;
        margin-bottom: 2.2vw;
      }

      .login_title {
        line-height: 1;
        margin-bottom: 1.55vw;
        @include text-styles(1.55vw, 500, $user-profile-name-color, left, $primary-font-family);
      }

      .form_item {
        width: 19.95vw;

        label {
          font-size: 0.87vw;
        }

        .login_input {
          min-height: 3.185vw;
        }

        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }

      .login_actions {
        padding-top: 1vw;
        display: flex;
        @include flex-box(flex-start, center, row);

        .primary_action {
          padding-left: 1.6vw;
          padding-right: 1.6vw;
          margin-right: 1.2vw;
        }

        .secondary_action {
          font-size: 0.68vw;
        }
      }

      .redirect_message {
        position: absolute;
        top: 1vw;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0;

        .redirect_message_content {
          margin-left: 1vw;
        }
      }
    }

    .forgot_password_form {
      .primary_action {
        margin-right: 1.2vw;
      }

      .secondary_action {
        font-size: 0.68vw;
        margin-top: 0.5vw;
      }

      .login_title {
        margin-bottom: 0.3vw;
      }

      .forgot_password_text {
        margin-bottom: 2.5vw;
        display: inline-block;
        max-width: 18vw;
        @include text-styles(0.72vw, 500, $role-color, left, $primary-font-family);
      }
    }
  }

  .footer_wrapper {
    background-color: transparent;
    border: none;
    color: #fff;
    .footer_inner {
      padding: 2.8vw;
      justify-content: center;
      .footer_left {
        margin-right: 6.591vw;
      }
    }
  }
}

.wrong_company {
  background-color: white;
  display: flex;
  @include flex-box(flex-start, flex-start, column);
  padding: 4vw 6vw;
}
.wrong_company_title {
  line-height: 1;
  margin-bottom: 1.55vw;
  @include text-styles(1.55vw, 500, $user-profile-name-color, left, $primary-font-family);
}
.wrong_company_subtitle {
  @include text-styles(0.8vw, 500, $role-color, left, $primary-font-family);
  margin-bottom: 0;
}


@include tablet-media {
  .login_page_container {
    .form_box {
      .company_container {
        padding: 5.161vw 0;

        .company_image_wrapper {
          &_default {
            padding: 1.953vw;

            .company_image {
              max-width: 8vw;
            }
          }
        }

        .company_image {
          max-width: 24.646vw;
        }

        .company_logo_text {
          width: 4.036vw;
          height: 2.214vw;
          font-size: 1.172vw;
          margin-left: 0.651vw;
          margin-bottom: 0.9vw;
        }
      }
      .login_form {
        padding: 7.594vw 9.827vw 6.288vw;

        .login_title {
          font-size: 3.527vw;
          margin-bottom: 4.427vw;
        }
        .form_item {
          width: 57.161vw;

          label {
            font-size: 2.474vw;
            margin-bottom: 2.865vw;
            sup {
              font-size: 2.865vw;
            }
          }
          .login_input {
            font-size: 2.344vw;
            min-height: 9.115vw;
            padding: 2.604vw 2.734vw;

            &::placeholder {
              font-size: 2.344vw;
            }
          }
        }
        .login_actions {
          .primary_action {
            border-radius: 6.6px;
            font-size: 2.083vw;
            margin-right: 3.385vw;
            padding: 2.604vw 4.557vw;
          }
          .secondary_action {
            font-size: 1.953vw;
          }
        }
        .wrong_credentials {
          h3 {
            font-size: 2.19vw;
          }
          .warning {
            width: 3vw;
            height: 3vw;
          }
        }
      }

      .forgot_password_form {
        .primary_action {
          border-radius: 6.6px;
          font-size: 2.083vw;
          margin-right: 3.385vw;
          padding: 2.604vw 4.557vw;
          margin-top: 1.5vw;
        }
        .secondary_action {
          font-size: 1.953vw;
          margin-top: 1.5vw;
        }
        .login_title {
          margin-bottom: 1.302vw;
        }
        .forgot_password_text {
          margin-bottom: 2.604vw;
          max-width: 50vw;
          font-size: 2.063vw;
        }
      }
    }

    .footer_wrapper {
      .footer_inner {
        padding: 3vw 11.8vw 3vw 11.4vw;
      }
    }
  }

  .wrong_company {
    width: 90%;
    padding: 7vw 8vw;
  }
  .wrong_company_title {
    font-size: 3.527vw;
    margin-bottom: 4.427vw;
  }
  .wrong_company_subtitle {
    font-size: 2.063vw;
  }
}



@include mobile-media {
  .login_page_container {
    .form_box {
      width: 87.5vw;

      .company_container {
        padding: 8.5vw 0;

        .company_image_wrapper {
          &_default {
            padding: 4vw;

            .company_image {
              max-width: 14vw;
            }
          }
        }

        .company_image {
          max-width: 46.333vw;
        }

        .company_logo_text {
          width: 8vw;
          height: 4.033vw;
          font-size: 2.667vw;
          margin-left: 1.333vw;
          margin-bottom: 2vw;
        }
      }
      .login_form {
        padding: 4vw 9.827vw 8.5vw;

        .login_title {
          font-size: 5.067vw;
          margin-bottom: 4vw;
        }
        .form_item {
          width: 68.161vw;
          margin-bottom: 4vw;

          label {
            font-size: 3.2vw;
            margin-bottom: 1.6vw;
            sup {
              font-size: 4vw;
            }
          }
          .login_input {
            font-size: 3.2vw;
            min-height: 10vw;
            padding: 3.033vw 3.6vw;
            border-width: 0.267vw !important;

            &::placeholder {
              font-size: 3.2vw;
            }
          }
          .error_sect {
            font-size: 3.2vw;
            padding-top: 1vw;
          }
        }
        .login_actions {
          @include flex-box(flex-start, center, column);
          width: 100%;
          margin-top: 3vw;

          .primary_action {
            border-radius: 6.6px;
            font-size: 3.867vw;
            margin-right: 0;
            padding: 3vw 8.333vw;
          }
          .secondary_action {
            margin-top: 2.6vw;
            font-size: 3.2vw;
          }
        }
        .wrong_credentials {
          h3 {
            font-size: 3.79vw;
          }
          .warning {
            width: 4.5vw;
            height: 4.5vw;
          }
        }
      }
      .forgot_password_form {
        .actions_sect {
          display: flex;
          @include flex-box(flex-start, flex-start, column);
          width: 100%;
        }
        .primary_action {
          border-radius: 6.6px;
          font-size: 3.2vw;
          margin-right: 0;
          padding: 3.133vw 6.133vw;
        }
        .secondary_action {
          margin-top: 2.6vw;
          font-size: 3.2vw;
        }
        .login_title {
          font-size: 5.067vw;
          margin-bottom: 2.667vw;
        }
        .forgot_password_text {
          margin-bottom: 4vw;
          max-width: unset;
          font-size: 3.2vw;
        }
      }
    }

    .footer_wrapper {
      .footer_inner {
        padding: 4vw 5.8vw 4vw 5.4vw;
        .footer_right {
          div {
            line-height: 1.3;
          }
        }
      }
    }
  }

  .wrong_company {
    width: 90%;
    padding: 7vw 8vw;
  }
  .wrong_company_title {
    font-size: 5.067vw;
    margin-bottom: 3.2vw;
  }
  .wrong_company_subtitle {
    font-size: 3.2vw;
  }
}
