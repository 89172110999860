@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/mixins";


.not_found_ui {
  width: 100%;
  display: flex;
  @include flex-box(center, center, column);
  padding: 2vw;
}
.not_found_ui_heading {
  font-size: 2.182vw;
}
.not_found_ui_content {
  font-size: 1.455vw;
  padding-bottom: 0;
}

@include tablet-media {
  .not_found_ui {
    padding: 5.729vw;
  }
  .not_found_ui_heading {
    font-size: 6.25vw;
  }
  .not_found_ui_content {
    font-size: 4.167vw;
  }
}

@include mobile-media {
  .not_found_ui {
    padding: 11.733vw;
  }
  .not_found_ui_heading {
    font-size: 9.8vw;
  }
  .not_found_ui_content {
    font-size: 6vw;
  }
}