@import "vars";

@mixin text-styles($fontSize, $fontWeight, $fontColor, $fontAlign, $fontFamily) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $fontColor;
  text-align: $fontAlign;
  font-family: $fontFamily;
}

@mixin flex-box($justify-content,$align-items,$flex-direction) {
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
}

@mixin img-styles($width,$height,$object-fit: cover,$border-radius: 50%) {
  width: $width;
  height: $height;
  object-fit: $object-fit;
  border-radius: $border-radius;
}

@mixin icon-styles($width,$height,$url,$backgroundSize) {
  width: $width;
  height: $height;
  background: url("../images/icons/" + $url) no-repeat;
  background-size: $backgroundSize;
}

@mixin text-elipses() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: 0.65vw;
    height: 0.65vw;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 0.273vw;
    border: 0.2rem solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #561884;
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin mobile-media {
  @media (max-width: $screen-sm - 1) {
    @content
  }
}

@mixin tablet-media {
  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    @content
  }
}

@mixin small-desktop-media {
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    @content
  }
}

@mixin desktop-media {
  @media (min-width: $screen-lg) and (max-width: $screen-xl) {
    @content
  }
}

